import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useBusinessAddr, useCartData, useLoading, useSessionData } from '../../hooks/order';
import earthlinkLogo from '../../images/elnk-top-hdr-mark.png';
import { sendBroadbandEmail } from '../../store/action/OrderAction';
import { getConfigs } from '../../config';

const config = getConfigs();

const BroadbandLabel = ({ setShowBroadbandContent }) => {
  const dispatch = useDispatch();
  const { isBusinessAddress } = useBusinessAddr();
  const { broadbandEmailError } = useCartData();
  const { broadbandEmailLoading } = useLoading();
  const { orderInfo, sessionResponse } = useSessionData();

  const { fccLabel, email } = orderInfo;

  // STATES
  const [isEmailSentSuccess, setIsEmailSentSuccess] = useState(false);

  const handleSendEmail = () => {
    const promoCode = isBusinessAddress
      ? sessionResponse?.businessPromoCode
      : sessionResponse?.promoCode;

    const partnerId = config?.RESIDENTIAL_PARTNER;

    dispatch(sendBroadbandEmail({ email, callLogId: fccLabel?.uniquePlanIdentifier }, promoCode, partnerId, fccLabel?.serviceRef)).then((res) => {
      if (res?.value?.status === 200) {
        setIsEmailSentSuccess(true)
      }
    });
  };

  return (
    <div className="broadband-label-container">
      <span className="back-btn" onClick={() => setShowBroadbandContent(false)}>
        <span className="back-icon">
          <i className="fa-solid fa-arrow-left"></i>
        </span>{' '}
        Back
      </span>

      {/* Header */}
      <div className="review-left-side">
        <div className="review_order_wrapp p_review_order_wrapp">
          <div className="rightsidebar-cart">
            <div className="common-cart-section">
              <div className="added-products one-time-change">
                <div className="product-collect-heading d-flex j-between">
                  <p className="label-heading">Broadband Facts</p>
                </div>
                <ul className="collected-product">
                  <li>
                    <div className="item-plan d-flex j-between">
                      <div></div>
                      <div>
                        <img
                          className="broadband-plan-earthlink-logo"
                          src={earthlinkLogo}
                          alt="earthlink logo"
                        />
                      </div>
                      <div className="dotted"></div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="common-cart-section">
              <div className="added-products">
                <ul className="collected-product">
                  <li>
                    <div className="d-flex j-between">
                      <div className="bolder-text">EarthLink</div>
                      <div className="dotted"></div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex j-between">
                      <div className="bolder-text">{fccLabel?.speedTier}</div>
                      <div className="dotted"></div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex j-between">
                      <div className="bolder-text">
                        {fccLabel?.wiredOrWireless} Broadband Consumer
                        Disclosure
                      </div>
                      <div className="dotted"></div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            {/* Monthly Data */}
            <div className="common-cart-section">
              <div className="added-products one-time-change">
                <div className="product-collect-heading d-flex j-between">
                  <p>Monthly Price</p>
                  <div className="product-heading">
                    {fccLabel?.monthlyPrice?.amount}
                  </div>
                </div>
                <ul className="collected-product">
                  <li>
                    <div className="item-plan d-flex j-between">
                      <div>{fccLabel?.monthlyPrice?.language}</div>
                      <div className="dotted"></div>
                    </div>
                  </li>
                  <li>
                    <div className="item-plan d-flex j-between">
                      <div>{fccLabel?.termLanguage}</div>
                      <div className="dotted"></div>
                    </div>
                  </li>
                  {fccLabel?.termsURL ? (
                    <li>
                      <div
                        className="item-plan d-flex j-between"
                        style={{ padding: '0px 2px' }}
                      >
                        <a
                          href={fccLabel?.termsURL}
                          target="_blank"
                          rel="noreferrer"
                        >
                          earthlink.net/tcs
                        </a>
                        <div className="dotted"></div>
                      </div>
                    </li>
                  ) : null}
                </ul>
              </div>
            </div>

            {/* Additional Charges */}
            <div className="common-cart-section">
              <div className="added-products one-time-change">
                <div className="product-collect-heading d-flex j-between">
                  <p>Additional Charges & Terms</p>
                </div>
                <ul className="collected-product">
                  <li>
                    <div className="item-plan d-flex j-between">
                      <div className="bolder-text">Provider Monthly Fees</div>
                      <div className="dotted"></div>
                    </div>
                  </li>
                  <li>
                    <div className="item-plan d-flex j-between">
                      <div>Monthly Modem Fee</div>
                      <div className="product-heading">
                        {fccLabel?.monthlyFees?.modemFee}
                      </div>
                      <div className="dotted"></div>
                    </div>
                  </li>
                  <li>
                    <div className="item-plan d-flex j-between">
                      <div>Monthly Fee for {fccLabel?.speedTier}</div>
                      <div className="product-heading">
                        {fccLabel?.monthlyFees?.mrcforFiber}
                      </div>
                      <div className="dotted"></div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            {/* One-time Fees */}
            <div className="common-cart-section">
              <div className="added-products one-time-change">
                <div className="product-collect-heading d-flex j-between">
                  <p>One-time Fees at the Time of Purchase</p>
                </div>
                <ul className="collected-product">
                  <li>
                    <div className="item-plan d-flex j-between">
                      <div>Setup Cost</div>
                      <div className="product-heading">
                        {fccLabel?.oneTimeFees}
                      </div>
                      <div className="dotted"></div>
                    </div>
                  </li>
                  <li>
                    <div className="item-plan d-flex j-between">
                      <div>Early Termination Fee</div>
                      <div className="product-heading">
                        {fccLabel?.etf?.cancPenaltyDesc}
                      </div>
                      <div className="dotted"></div>
                    </div>
                  </li>
                  <li>
                    <div className="item-plan d-flex j-between">
                      <div>Government Taxes</div>
                      <div className="product-heading">
                        {fccLabel?.governmentTaxes}
                      </div>
                      <div className="dotted"></div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            {/* Discounts & Bundles */}
            <div className="common-cart-section">
              <div className="added-products one-time-change">
                <div className="product-collect-heading d-flex j-between">
                  <p>Discounts & Bundles</p>
                </div>
                <ul className="collected-product">
                  <li>
                    <div className="item-plan d-flex j-between">
                      <div>{fccLabel?.discountsAndBundles}</div>
                      <div className="dotted"></div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            {/* Speeds Provided with Plan */}
            <div className="common-cart-section">
              <div className="added-products one-time-change">
                <div className="product-collect-heading d-flex j-between">
                  <p>Speeds Provided with Plan</p>
                </div>
                <ul className="collected-product">
                  <li>
                    <div className="item-plan d-flex j-between">
                      <div>Typical Download Speed</div>
                      <div className="product-heading">
                        {fccLabel?.speedsProvidedWithPlans?.download}
                      </div>
                      <div className="dotted"></div>
                    </div>
                  </li>
                  <li>
                    <div className="item-plan d-flex j-between">
                      <div>Typical Upload Speed</div>
                      <div className="product-heading">
                        {fccLabel?.speedsProvidedWithPlans?.upload}
                      </div>
                      <div className="dotted"></div>
                    </div>
                  </li>
                  <li>
                    <div className="item-plan d-flex j-between">
                      <div>Typical Latency</div>
                      <div className="product-heading">
                        {fccLabel?.speedsProvidedWithPlans?.latency}
                      </div>
                      <div className="dotted"></div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            {/* Data Included */}
            <div className="common-cart-section">
              <div className="added-products one-time-change">
                <ul className="collected-product">
                  <li>
                    <div className="item-plan d-flex j-between">
                      <div>Data Included with Monthly Price</div>
                      <div className="product-heading">
                        {fccLabel?.dataIncludedWithMonthlyPrice?.data}
                      </div>
                      <div className="dotted"></div>
                    </div>
                  </li>
                  <li>
                    <div className="item-plan d-flex j-between">
                      <div>Charges for Additional Data Usage</div>
                      <div className="product-heading">
                        {fccLabel?.dataIncludedWithMonthlyPrice?.amount}
                      </div>
                      <div className="dotted"></div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            {/* Network Management */}
            <div className="common-cart-section">
              <div className="added-products one-time-change">
                <ul className="collected-product">
                  <li>
                    <div className="item-plan d-flex j-between">
                      <div className="product-heading">Network Management</div>
                      <a
                        href={fccLabel?.networkManagement}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Read our Policy
                      </a>
                      <div className="dotted"></div>
                    </div>
                  </li>
                  <li>
                    <div className="item-plan d-flex j-between">
                      <div className="product-heading">Privacy</div>
                      <a
                        href={fccLabel?.privacy}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Read our Policy
                      </a>
                      <div className="dotted"></div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            {/* Customer Support */}
            <div className="common-cart-section">
              <div className="added-products one-time-change">
                <div className="product-collect-heading d-flex j-between label-footer">
                  <p>Customer Support</p>
                </div>
                <div className="item-plan d-flex j-between">
                  <div>Contact Us</div>
                  <div className="product-heading">
                    <a
                      href={fccLabel?.customerSupport?.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      earthlink.net/about-us/contact
                    </a>{' '}
                    {fccLabel?.customerSupport?.phoneNumber && (
                      <span>/ {fccLabel.customerSupport.phoneNumber}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Learn More */}
            <div className="common-cart-section">
              <div className="added-products one-time-change">
                <ul className="collected-product">
                  <li>
                    <div className="item-plan">
                      <div className="d-block" style={{ display: 'block' }}>
                        Learn more about the terms used on this label by
                        visiting the Federal Communications Commission&apos;s
                        Consumer Resource Center.{' '}
                        <a
                          href={fccLabel?.fccURL}
                          target="_blank"
                          rel="noreferrer"
                        >
                          fcc.gov/consumer
                        </a>
                      </div>
                      <div className="dotted"></div>
                    </div>
                  </li>
                  <li>
                    <div className="item-plan d-flex j-between">
                      <div className="product-heading">
                        Unique Plan Identifier: {fccLabel?.uniquePlanIdentifier}
                      </div>
                      <div className="dotted"></div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            {/* Send Email Button */}
            <button
              className="blue-button"
              disabled={broadbandEmailLoading}
              onClick={handleSendEmail}
            >
              <span>
                <i className="fa-solid fa-envelope"></i> &nbsp; Share via Email
              </span>
            </button>

            {isEmailSentSuccess && !broadbandEmailLoading && (
              <div className="d-block text-green .m-top-1">
                Broadband Facts email successfully sent
              </div>
            )}

            {broadbandEmailError && (
              <div className="red-color .m-top-1">
                Something went wrong. Please retry
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

BroadbandLabel.propTypes = {
  setShowBroadbandContent: PropTypes.func.isRequired,
};

export default BroadbandLabel;
