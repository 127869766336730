import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useCallback,
} from 'react';
import { Form, Input, Checkbox, Row, Col, Collapse, Select } from 'antd';
import validator from 'validator';
import {
  useAccountCheck,
  useBusinessAddr,
  useCartData,
  useLoading,
  useMbox,
  useServiceablitityData,
  useSessionData,
  useVasData,
} from '../../../hooks/order';
import {
  checkTollFreeNumber,
  getAggregatedAddress,
  getCallLogId,
  getValidAcctEmail,
  handleAlphaKeyPress,
  handleNumericKeyPress,
  isUndefinedNullOrEmpty,
} from '../../../common/common';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { ValidationMessages } from '../../../common/validationMessages';
import PhoneInput from 'react-phone-number-input/input';
import { useDispatch } from 'react-redux';
import {
  accountCheck,
  saveBusinessInfo,
  saveTCPADisclaimer,
  updateErrorMsg,
  updateOrder,
  saveSpanishLanguagePreference,
} from '../../../store/action/OrderAction';
import {
  getAccountCheckModel,
  getEnterInfoModel,
} from '../../../helpers/OrderHelperFunctions';
import {
  alphaWithSpaceRegex,
  emailRegex,
  notOnlyNumerics,
} from '../../../util/regex';
import { Loader } from '../../common/Loader';
import { api } from '../../../util/axios';
import { getConfigs } from '../../../config';
import AddressSuggestionSelect from '../../common/AddressSuggestionSelect';
import { STATES } from '../../../data/state.data';
import spinner from '../../../images/spinner.gif';
import PropTypes from 'prop-types';

const config = getConfigs();

const EnterInformation = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { loading } = useLoading();
  const { isVasOnlyFlow } = useVasData();
  const { serviceabilityCheck } = useServiceablitityData();
  const { isBusinessAddress, businessInfo } = useBusinessAddr();
  const { orderInfo } = useSessionData();
  const { normalizedAddress } = useServiceablitityData();
  const { address1, address2, city, state, zipCode } = normalizedAddress;
  const {
    id,
    shippingAddress,
    shippingAdd1,
    shippingAdd2,
    shippingCity,
    shippingState,
    shippingZip,
  } = orderInfo;
  const [form] = Form.useForm();
  const [disclaimerCheck, setDisclaimerCheck] = useState(false);
  const [languagePref, setLanguagePref] = useState(false);
  const [initialState, setInitialState] = useState({});
  const [emailVerificationError, setEmailVerificationError] = useState('');
  const [emailVerificationLoading, setEmailVerificationLoading] =
    useState(false);
  const [phoneVerificationError, setPhoneVerificationError] = useState('');
  const [phoneVerificationLoading, setPhoneVerificationLoading] = useState(false);
  const { isElnkMailboxReq } = useMbox();
  const { checkAccount } = useAccountCheck();
  const [emailInp, setEmailInp] = useState('');
  const emailInputRef = useRef();
  const { internetPlan } = useCartData();
  const [open, setOpen] = useState([]);
  const [isAddressDifferent, setIsAddressDifferent] = useState(false);
  const [submittable, setSubmittable] = useState(false);
  const { Panel } = Collapse;
  const { Option } = Select;

  const [isManualAddr, setIsManualAddr] = useState(false);
  const [manualAddress, setManualAddress] = useState({
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
  });
  const [shippingAddrState, setShippingState] = useState({
    shipAdd1: isUndefinedNullOrEmpty(shippingAdd1)
      ? normalizedAddress.address1
      : shippingAdd1,
    shipAdd2: isUndefinedNullOrEmpty(shippingAdd2)
      ? normalizedAddress.address2
      : shippingAdd2,
    shipCity: isUndefinedNullOrEmpty(shippingCity)
      ? normalizedAddress.city
      : shippingCity,
    shipState: isUndefinedNullOrEmpty(shippingState)
      ? normalizedAddress.state
      : shippingState,
    shipZip: isUndefinedNullOrEmpty(shippingZip)
      ? normalizedAddress.zipCode
      : shippingZip,
    shipAddress: isUndefinedNullOrEmpty(shippingAddress)
      ? getAggregatedAddress(normalizedAddress)
      : shippingAddress,
  });

  const [mobileType, setMobileType] = useState('Mobile');

  const { shipAdd1, shipAdd2, shipCity, shipState, shipZip } =
    shippingAddrState;


  const valuesStatus = Form.useWatch([], form);


  const setShipping = (searchAddress) => {
    setShippingState({
      ...shippingAddrState,
      shipAdd1: searchAddress.address1,
      shipAdd2: searchAddress.address2,
      shipCity: searchAddress.city,
      shipState: searchAddress.state,
      shipZip: searchAddress.zip,
    });

    form.setFieldsValue({
      shippingAddress: getAggregatedAddress({
        address1: searchAddress.address1,
        address2: searchAddress.address2,
        city: searchAddress.city,
        state: searchAddress.state,
        zipCode: searchAddress.zip,
      }),
    });
  };

  useEffect(() => {
    if (orderInfo && !isUndefinedNullOrEmpty(orderInfo.firstName)) {
      setInitialState({ ...orderInfo, ...businessInfo });
    }
    form.setFieldsValue({
      phoneNum: orderInfo.phoneNum ? '+1' + orderInfo.phoneNum : '',
    });
  }, []);

  const handleSubmit = (values) => {
    const callLogId = getCallLogId();
    const { firstName, lastName, email, phoneNum, installPhone } = values;
    const { serviceCheckId } = serviceabilityCheck;

    if (!emailVerificationError && !emailVerificationLoading) {
      if (isBusinessAddress) {
        const { businessName, businessPhone, noOfEmployees, noOfLocations } =
          values;
        dispatch(
          saveBusinessInfo({
            businessName,
            businessPhone,
            noOfEmployees,
            noOfLocations,
          })
        );
      }
      dispatch(saveTCPADisclaimer(disclaimerCheck));

      if (!isUndefinedNullOrEmpty(id)) {
        const personalInfoModel = getEnterInfoModel(
          firstName,
          lastName,
          email,
          phoneNum?.slice(-10),
          callLogId,
          serviceCheckId
        );
        const installPhoneValue =
          !isUndefinedNullOrEmpty(installPhone) && installPhone?.length === 12
            ? { installPhone: installPhone?.slice(2) }
            : {};

        const finalShipAddress = getAggregatedAddress({
          address1: shipAdd1,
          address2: shipAdd2,
          city: shipCity,
          state: shipState,
          zipCode: shipZip,
        });
        const finalServiceAddress = getAggregatedAddress({
          address1,
          address2,
          city,
          state,
          zipCode,
        });

        const manualShipAddress = getAggregatedAddress({
          address1: manualAddress.address1,
          address2: manualAddress.address2,
          city: manualAddress.city,
          state: manualAddress.state,
          zipCode: manualAddress.zip,
        });

        let ReqBody = {};

        if (isManualAddr) {
          ReqBody = {
            ...personalInfoModel,
            ...installPhoneValue,
            ...{
              shippingAdd1: manualAddress.address1,
              shippingAdd2: manualAddress.address2,
              shippingAddress: manualShipAddress,
              shippingCity: manualAddress.city,
              shippingState: manualAddress.state,
              shippingZip: manualAddress.zip,
            },
          };
        } else {
          ReqBody = {
            ...personalInfoModel,
            ...installPhoneValue,
            ...(internetPlan?.servCode === 'ELFW' && {
              shippingAdd1: isAddressDifferent ? shipAdd1 : address1,
              shippingAdd2: isAddressDifferent ? shipAdd2 : address2,
              shippingAddress: isAddressDifferent
                ? finalShipAddress
                : finalServiceAddress,
              shippingCity: isAddressDifferent ? shipCity : city,
              shippingState: isAddressDifferent ? shipState : state,
              shippingZip: isAddressDifferent ? shipZip : zipCode,
            }),
          };
        }

        dispatch(saveSpanishLanguagePreference(languagePref));

        // SAVE TO DB CALL
        dispatch(updateOrder(ReqBody, id))
          .then(() => {
            props.setActiveKey('4');
          })
          .catch((error) => {
            dispatch(updateErrorMsg(error));
          });
      }
    }
  };

  const handleKeyPress = (value) => {
    const phoneEl = document.getElementById(value);
    const phoneNumber = phoneEl && phoneEl?.value;
    if (!isUndefinedNullOrEmpty(phoneNumber)) {
      if (
        phoneNumber.length === 1 &&
        (phoneNumber === '0' || phoneNumber === '1')
      ) {
        phoneEl.value = '';
      }
      if (phoneNumber.charAt(0) === '1' || phoneNumber.charAt(0) === '0') {
        phoneEl.value = '';
      }
    }
    return;
  };

  const handleKeyDown = (value) => {
    const num = document.getElementById(value);
    const number = num && num?.value;
    if (!isUndefinedNullOrEmpty(number)) {
      if (number.length === 1 && number === '0') {
        num.value = '';
      }
    }
    return;
  };

  const checkEmail = () => {
    const email = emailInp.trim();

    if (!email || email?.length === 0) {
      setEmailVerificationError('Please enter Email Address');
    }
  };

  const verifyEmail = () => {
    const email = emailInp.trim();
    if (!email || email?.length === 0) {
      setEmailVerificationError('Please enter Email Address');
      setEmailVerificationLoading(false);
    } else {
      form.setFieldsValue({ email: email?.trim() });

      api
        .axios({
          method: 'GET',
          url: `${config.API_ENDPOINT}/emails/verify?email=${email}&appClientId=ecom`,
        })
        .then((res) => {
          const { result } = res.data;

          if (result === 'undeliverable') {
            setEmailVerificationError(
              'Invalid Email or Domain. Please re-enter Email Address'
            );
          }

          setEmailVerificationLoading(false);
        })
        .catch(() => setEmailVerificationLoading(false));
    }
  };

  const checkAcctAvail = () => {
    setEmailVerificationLoading(true);

    const email = emailInp.trim();
    const emailValid = validator.isEmail(email) && emailRegex.test(email);

    if (!email || !emailValid) {
      setEmailVerificationError(ValidationMessages.invalidEmail);
      setEmailVerificationLoading(false);
      return;
    }

    setEmailVerificationError('');

    if (!isElnkMailboxReq) {
      const accEmail = getValidAcctEmail(email, isElnkMailboxReq);
      const { firstName, lastName } = form.getFieldsValue([
        'firstName',
        'lastName',
      ]);

      if (
        accEmail &&
        !isUndefinedNullOrEmpty(accEmail) &&
        !isUndefinedNullOrEmpty(firstName) &&
        !isUndefinedNullOrEmpty(lastName)
      ) {
        const accountCheckModel = getAccountCheckModel(
          firstName,
          lastName,
          accEmail
        );

        dispatch(accountCheck(accountCheckModel))
          .then(() => {
            if (checkAccount && checkAccount.emailIsAvailable) {
              props.setShowCallSupportModal(false);
              verifyEmail();
            } else {
              props.setShowCallSupportModal(true);
            }
          })
          .catch(() => {
            props.setShowCallSupportModal(true);
          })
          .finally(() => {
            setEmailVerificationLoading(false);
          });
      } else {
        verifyEmail();
        setEmailVerificationLoading(false);
      }
    } else {
      verifyEmail();
      setEmailVerificationLoading(false);
    }
  };

  const handleOnBlurPhone = useCallback(() => {
    setPhoneVerificationError('');

    let phoneVal = form.getFieldValue('phoneNum')
    const cleanedPhoneNumber = phoneVal.replace(/[()-\s]/g, '');
    if (checkTollFreeNumber(phoneVal?.toString())) {
      setPhoneVerificationError('Please provide a valid mobile phone number');
    } else if (phoneVal?.length > 11) {
      setPhoneVerificationLoading(true);
      verifyMobileApi(cleanedPhoneNumber)
        .then((res) => {
          setPhoneVerificationLoading(false);
          if (res?.data?.response?.carrier?.type === 'mobile') {
            setPhoneVerificationError('');
          }
        })
        .catch(() => {
          setPhoneVerificationLoading(false);
          setPhoneVerificationError(
            'Please provide a valid mobile phone number'
          );
        });
    }
  }, [form]);

  const handleOnBlurName = async () => {
    const email = emailInp.trim();

    if (
      !props.showCreateAccTile &&
      !isElnkMailboxReq &&
      form.getFieldValue('firstName') &&
      form.getFieldValue('lastName') &&
      email
    ) {
      try {
        checkAcctAvail();
      } catch { }
    }
  };

  useImperativeHandle(ref, () => ({
    emailEdit() {
      handleEditEmail();
    },
  }));

  const handleEditEmail = () => {
    props.setShowCallSupportModal(false);
    form.setFieldsValue({
      email: '',
    });
    setEmailInp('');
    setTimeout(() => {
      emailInputRef?.current?.input.focus();
    }, 300);
    return;
  };

  const handleManualChange = (e, name) => {
    setManualAddress({
      ...manualAddress,
      [name]: name === 'state' ? e : e.target.value,
    });
  };

  const verifyMobileApi = (phoneVal) => {
    return api.axios({
      method: 'GET',
      url: `${config.CONSUMER_AUTH}/sms/check/${phoneVal?.slice(-10)}`,
    });
  };

  const handleOnBlurInstallPhone = useCallback(() => {
    let phoneVal = form.getFieldValue('installPhone');
    if (phoneVal?.length > 11) {
      verifyMobileApi(phoneVal)
        .then((res) => {
          if (res?.data?.response?.carrier?.type === 'mobile') {
            setMobileType('Mobile');
          }
        })
        .catch((e) => {
          let typeResp = e?.response?.data?.message?.toLowerCase();
          if (typeResp === 'not mobile type' || typeResp === 'bad request') {
            setMobileType('Other');
          } else {
            setMobileType(
              typeResp?.charAt(0)?.toUpperCase() + typeResp?.slice(1)
            );
          }
        });
    }
  }, []);

  const isVaildManualAddress =
    manualAddress.address1 !== '' &&
    manualAddress.city !== '' &&
    manualAddress.zip !== '' &&
    manualAddress.zip.length > 4 &&
    manualAddress.state !== '';
  const buttonCheckAvailability =
    isAddressDifferent &&
      isManualAddr &&
      !isVaildManualAddress &&
      emailVerificationLoading
      ? true
      : false;

  // Watch all values
  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, valuesStatus]);

  return (
    <>
      <div className="your-info-tab same-common">
        <Form
          layout="vertical"
          name="basic"
          initialValues={initialState}
          onFinish={handleSubmit}
          autoComplete="off"
          form={form}
          validateTrigger={'onBlur'}
        >
          <Row className="yor-info-row j-between">
            {isBusinessAddress && (
              <>
                <Col span={24} className="p-left-15-p responsive-width-set">
                  <h2>Business Information</h2>
                </Col>
                <Col span={12} className="your-info-input responsive-width-set">
                  <Form.Item
                    label="Business Name"
                    name="businessName"
                    validateFirst={'true'}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: 'Please enter Business Name',
                      },
                      {
                        min: 2,
                        message: `${ValidationMessages.min2Length}`,
                      },
                      {
                        pattern: notOnlyNumerics,
                        message: `${ValidationMessages.notOnlyNumerics}`,
                      },
                    ]}
                  >
                    <Input maxLength="30" />
                  </Form.Item>
                </Col>
                <Col span={12} className="your-info-input responsive-width-set">
                  <Form.Item
                    label="Business Phone"
                    name="businessPhone"
                    rules={[
                      { required: true, message: 'Enter a phone number' },
                      {
                        min: 10,
                        message: `${ValidationMessages.min10Length}`,
                      },
                      {
                        validator(_, value) {
                          if (!value) {
                            return Promise.resolve();
                          } else if (
                            !isValidPhoneNumber(value) &&
                            value.length > 10
                          ) {
                            return Promise.reject(
                              `${ValidationMessages.invalidPhoneNumber}`
                            );
                          } else if (
                            checkTollFreeNumber(value.toString()) &&
                            value.length > 10
                          ) {
                            return Promise.reject(
                              'You can not enter toll free number'
                            );
                          } else {
                            return Promise.resolve();
                          }
                        },
                      },
                    ]}
                  >
                    <PhoneInput
                      smartCaret={false}
                      useNationalFormatForDefaultCountryValue={true}
                      defaultCountry="US"
                      withCountryCallingCode={false}
                      autoComplete="off"
                      maxLength={14}
                      name="businessPhone"
                      id="businessPhone"
                      className="ant-input"
                      disableCountryCode={true}
                      country="US"
                      onKeyPress={() => handleKeyPress('businessPhone')}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} className="your-info-input responsive-width-set">
                  <Form.Item
                    label="Number of Locations"
                    name="noOfLocations"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter Number of Locations',
                      },
                      {
                        validator(_, value) {
                          if (value && (value === '0' || value === 0)) {
                            return Promise.reject(
                              new Error(
                                `${ValidationMessages.businessRequired}`
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      maxLength="4"
                      onKeyPress={handleNumericKeyPress}
                      id="noOfLocations"
                      onKeyDown={() => {
                        handleKeyDown('noOfLocations');
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} className="your-info-input responsive-width-set">
                  <Form.Item
                    label="Number of Employees"
                    name="noOfEmployees"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter Number of Employees',
                      },
                      {
                        validator(_, value) {
                          if (value && (value === '0' || value === 0)) {
                            return Promise.reject(
                              new Error(
                                `${ValidationMessages.businessRequired}`
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      maxLength="4"
                      min="0"
                      onKeyPress={handleNumericKeyPress}
                      id="noOfEmployees"
                      onKeyDown={() => {
                        handleKeyDown('noOfEmployees');
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} className="p-left-15-p">
                  <h2>Name and Contact Information</h2>
                </Col>
              </>
            )}
            <Col span={12} className="your-info-input responsive-width-set">
              <Form.Item
                label="First Name"
                name="firstName"
                validateFirst={'true'}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'Please enter First Name',
                  },
                  {
                    min: 2,
                    message: `${ValidationMessages.min2Length}`,
                  },
                  {
                    validator(_, value) {
                      if (!isUndefinedNullOrEmpty(value)) {
                        value = value.trim();
                        form.setFieldsValue({ firstName: value });
                        if (!alphaWithSpaceRegex.test(value)) {
                          form.setFieldsValue({ firstName: '' });
                          return;
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  placeholder="First Name"
                  maxLength="30"
                  onBlur={handleOnBlurName}
                  onKeyPress={handleAlphaKeyPress}
                />
              </Form.Item>
            </Col>
            <Col span={12} className="your-info-input responsive-width-set">
              <Form.Item
                label="Last Name"
                name="lastName"
                validateFirst={'true'}
                dependencies={['firstName']}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'Please enter Last Name',
                  },
                  {
                    min: 2,
                    message: `${ValidationMessages.min2Length}`,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!isUndefinedNullOrEmpty(value)) {
                        value = value.trim();
                        form.setFieldsValue({ lastName: value });
                        if (!alphaWithSpaceRegex.test(value)) {
                          form.setFieldsValue({ lastName: '' });
                          return;
                        }
                        if (
                          value &&
                          getFieldValue('firstName').toLowerCase() ===
                          value.toLowerCase()
                        ) {
                          return Promise.reject(
                            new Error('Values must be unique')
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input
                  placeholder="Last Name"
                  maxLength="30"
                  onBlur={handleOnBlurName}
                  onKeyPress={handleAlphaKeyPress}
                />
              </Form.Item>
            </Col>
            <Col span={12} className="your-info-input responsive-width-set">
              <Form.Item label="Email Address" name="email">
                <Input
                  placeholder="email@address.com"
                  value={emailInp}
                  onChange={(e) => setEmailInp(e.target.value)}
                  onBlur={checkAcctAvail}
                  ref={emailInputRef}
                  style={{ borderColor: emailVerificationError ? 'red' : '' }}
                />
                {emailVerificationError ? (
                  <span style={{ color: 'red' }}>{emailVerificationError}</span>
                ) : (
                  emailVerificationLoading && (
                    <img
                      src={spinner}
                      alt=""
                      style={{ width: '15px', height: '15px' }}
                    />
                  )
                )}
              </Form.Item>
            </Col>
            <Col span={12} className="your-info-input responsive-width-set">
              <Form.Item
                label="Primary Number"
                name="phoneNum"
                rules={[
                  { required: true, message: 'Enter a phone number' },
                  {
                    min: 10,
                    message: `${ValidationMessages.min10Length}`,
                  },
                  {
                    validator(_, value) {
                      if (!value) {
                        return Promise.resolve();
                      } else if (
                        !isValidPhoneNumber(value) &&
                        value.length > 10
                      ) {
                        return Promise.reject(
                          `${ValidationMessages.invalidPhoneNumber}`
                        );
                      } else if (
                        checkTollFreeNumber(value.toString()) &&
                        value.length > 10
                      ) {
                        return Promise.reject(
                          'You can not enter toll free number'
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
                validateStatus={phoneVerificationError ? 'error' : undefined}
                help={(!phoneVerificationLoading && phoneVerificationError) || undefined}
              >
                <PhoneInput
                  smartCaret={false}
                  useNationalFormatForDefaultCountryValue={true}
                  defaultCountry="US"
                  withCountryCallingCode={false}
                  autoComplete="off"
                  maxLength={14}
                  name="phoneNum"
                  id="phoneNum"
                  type="tel"
                  placeholder="Enter Mobile Number"
                  className="ant-input"
                  disableCountryCode={true}
                  country="US"
                  onKeyPress={() => handleKeyPress('phoneNum')}
                  onBlur={handleOnBlurPhone}
                />
              </Form.Item>
              {phoneVerificationLoading && <img alt="Loading..." src={spinner} style={{
                width: '15px', height: '15px', position: 'absolute',
                top: '80%'
              }} />}
            </Col>
            {internetPlan?.servCode === 'ELFW' && (
              <>
                <Col
                  span={20}
                  className="your-info-input responsive-width-set ship-addr-col"
                >
                  <label htmlFor="shipAddress" className="custom-label">
                    Add Shipping Address{' '}
                    <span style={{ color: 'red' }}>
                      (if different from Service)
                    </span>
                  </label>
                </Col>
                <Col span={4} className="your-info-input responsive-width-set">
                  <div className="shipping_icon text-right">
                    <i
                      className={`fas fa-${isAddressDifferent ? 'minus' : 'plus'}-circle`}
                      onClick={() => setIsAddressDifferent((prev) => !prev)}
                      onKeyDown={() => { }}
                    ></i>
                  </div>
                </Col>
                {isAddressDifferent && (
                  <>
                    <Col
                      span={18}
                      className="your-info-input responsive-width-set"
                    >
                      <Form.Item
                        className="address-on-card"
                        name="shippingAddress"
                        initialValue={getAggregatedAddress({
                          address1: shippingAdd1,
                          address2: shippingAdd2,
                          city: shippingCity,
                          state: shippingState,
                          zipCode: shippingZip,
                        })}
                      >
                        {
                          <AddressSuggestionSelect
                            autoFocus={false}
                            autoComplete="off"
                            parentComp="shipping"
                            className="shippingInput"
                            setShipping={setShipping}
                            val={shippingAddrState.shipAddress}
                            name="shippingAddress"
                            disabled={isManualAddr}
                          />
                        }
                      </Form.Item>
                    </Col>
                    <Col
                      span={6}
                      className="your-info-input marin-top-8 responsive-width-set"
                    >
                      <Checkbox
                        checked={isManualAddr}
                        onChange={() => setIsManualAddr(!isManualAddr)}
                      >
                        Enter Manually
                      </Checkbox>
                    </Col>
                    {isManualAddr && (
                      <>
                        <Col
                          span={12}
                          className="your-info-input responsive-width-set"
                        >
                          <Form.Item label="Street Address" name="address1">
                            <Input
                              value={manualAddress?.address1}
                              onChange={(e) =>
                                handleManualChange(e, 'address1')
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          span={12}
                          className="your-info-input responsive-width-set"
                        >
                          <Form.Item label="Apt, Flr, Unit" name="address2">
                            <Input
                              value={manualAddress?.address2}
                              onChange={(e) =>
                                handleManualChange(e, 'address2')
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          span={8}
                          className="your-info-input responsive-width-set"
                        >
                          <Form.Item label="City" name="city">
                            <Input
                              value={manualAddress?.city}
                              onChange={(e) => handleManualChange(e, 'city')}
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          span={8}
                          className="your-info-input responsive-width-set"
                        >
                          <Form.Item
                            label="State"
                            name="state"
                            style={{ fontSize: '16px' }}
                          >
                            <Select
                              id="stateInput"
                              value={manualAddress?.state}
                              onChange={(e) => handleManualChange(e, 'state')}
                            >
                              {Object.keys(STATES).map((abbreviation) => (
                                <Select.Option
                                  key={abbreviation}
                                  value={abbreviation}
                                >
                                  {abbreviation}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col
                          span={8}
                          className="your-info-input responsive-width-set"
                        >
                          <Form.Item label="Zip Code" name="zipCode">
                            <Input
                              maxLength="5"
                              value={manualAddress?.zip}
                              onKeyPress={handleNumericKeyPress}
                              onChange={(e) => handleManualChange(e, 'zip')}
                            />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </Row>
          {!isVasOnlyFlow && (
            <>
              <div className="primary-info">
                <b>Primary Number </b>is used to communicate via phone/text
                messages regarding details of your order and other account
                related communications in the future. Want to add a different
                number for Activation purposes only? Click{' '}
                <a onClick={() => setOpen(['1'])} onKeyDown={() => { }}>
                  here
                </a>
                .
              </div>
              {/* -----Intallation Number---- */}
              <Col span={24} className="activation_number_collapse">
                <Collapse
                  className="installNum"
                  activeKey={open}
                  onChange={() => setOpen(() => [1])}
                >
                  <Panel key="1">
                    <Row>
                      <Col span={8}>
                        <Form.Item
                          name="installPhone"
                          dependencies={['phoneNum']}
                          rules={[
                            {
                              min: 12,
                              message: `${ValidationMessages.min10Length}`,
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (!value) {
                                  return Promise.resolve();
                                } else if (
                                  value &&
                                  getFieldValue('phoneNum') &&
                                  getFieldValue('phoneNum') === value
                                ) {
                                  return Promise.reject(
                                    new Error(
                                      'Must be diffrent from Primary number'
                                    )
                                  );
                                } else {
                                  return Promise.resolve();
                                }
                              },
                            }),
                          ]}
                        >
                          <PhoneInput
                            placeholder="Activation Number"
                            smartCaret={false}
                            useNationalFormatForDefaultCountryValue={true}
                            defaultCountry="US"
                            withCountryCallingCode={false}
                            autoComplete="off"
                            maxLength={14}
                            name="InstallPhoneNum"
                            id="InstallPhoneNum"
                            className="ant-input"
                            disableCountryCode={true}
                            country="US"
                            onKeyPress={() => handleKeyPress('InstallPhoneNum')}
                            onBlur={handleOnBlurInstallPhone}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Row>
                          <Form.Item>
                            <Input
                              className="mobile-type-input"
                              value={mobileType}
                              disabled
                            />
                          </Form.Item>
                        </Row>
                      </Col>
                      <Col span={8}>
                        <Row>
                          <label>Label</label>
                          <Form.Item>
                            <Select defaultValue="mobile">
                              <Option value="mobile">Mobile</Option>
                              <Option value="home">Home</Option>
                              <Option value="work">Work</Option>
                              <Option value="other">Other</Option>
                            </Select>
                          </Form.Item>
                        </Row>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Col>
            </>
          )}

          <div className="your-info-beolow-des">
            <Checkbox
              onClick={() => setDisclaimerCheck(!disclaimerCheck)}
              checked={disclaimerCheck}
              onChange={() => setDisclaimerCheck(!disclaimerCheck)}
            >
              <b>Interested in learning more?</b> By submitting your information
              as your electronic signature, you authorize EarthLink and its
              affiliates to send you marketing text messages, emails, recordings
              or to make calls, via automated technology, at any time to the
              number and email provided. You also agree to comply with
              EarthLink’s Terms and Conditions, including the mandatory
              arbitration provisions, at{' '}
              <a
                href={`${config.NEXT_BASE_URL}/tcs`}
                target="_blank"
                rel="noreferrer"
              >
                EarthLink.net/tcs
              </a>
              . Consent is not required to purchase.
            </Checkbox>
          </div>
          <div className="your-info-beolow-des">
            <Checkbox
              onClick={() => setLanguagePref(!languagePref)}
              checked={languagePref}
              onChange={() => setLanguagePref(!languagePref)}
            >
              <b>Spanish language preferred</b>
            </Checkbox>
          </div>
          <div className="text-right enter-your-info">
            {loading ? (
              <Loader />
            ) : (
              <button
                className="blue-button"
                type="submit"
                onClick={checkEmail}
                disabled={buttonCheckAvailability || emailVerificationLoading || phoneVerificationLoading || !submittable}
              >
                Continue
              </button>
            )}
          </div>
        </Form>
      </div>
    </>
  );
});

EnterInformation.propTypes = {
  setShowCallSupportModal: PropTypes.func.isRequired,
};

export default EnterInformation;
