import {
  getSegregatedAddress,
  getCallLogId,
} from '../common/common';
import { OrderStatus, SecQueOptions } from '../util/util';
import { getConfigs } from '../config';

const config = getConfigs();


export const getPersonalInfoModel = (
  fName,
  lName,
  callLogId,
  email,
  num,
  cellPhoneTermsAgreed,
  serviceCheckId,
) => {

  let orderInfoModel = {};
  // PERSONAL DETAILS
  orderInfoModel.firstName = fName;
  orderInfoModel.lastName = lName;
  orderInfoModel.email = email;
  orderInfoModel.phoneNum = num;
  orderInfoModel.orderStatus = OrderStatus.basicInfo;
  orderInfoModel.callLogId = callLogId;
  orderInfoModel.cellPhoneTermsAgreed = cellPhoneTermsAgreed;
  orderInfoModel.serviceCheckId = serviceCheckId;
  return orderInfoModel;
}


export const getEnterInfoModel = (
  firstName,
  lastName,
  email,
  phoneNum,
  callLogId,
  serviceCheckId,
  cellPhoneTermsAgreed
) => {
  let enterInfoModel = {
    firstName,
    lastName,
    email,
    phoneNum,
    callLogId,
    serviceCheckId,
    cellPhoneTermsAgreed,
    orderStatus: OrderStatus.basicInfo
  };
  return enterInfoModel;
};

export const getServiceAddressModel = (
  serviceAddress,
  serviceAdd1,
  serviceAdd2,
  serviceCity,
  serviceState,
  serviceZip
) => {
  let serviceAddressModel = {};
  serviceAddressModel.serviceAddress = serviceAddress;
  serviceAddressModel.serviceAdd1 = serviceAdd1;
  serviceAddressModel.serviceAdd2 = serviceAdd2;
  serviceAddressModel.serviceCity = serviceCity;
  serviceAddressModel.serviceState = serviceState;
  serviceAddressModel.serviceZip = serviceZip;
  return serviceAddressModel;
};

export const getCheckServiceableModel = (address, suggestedAddress, businessAddress, promoCode, affiliateID, businessPromoCode) => {
  let checkServiceabilityModel = {};
  checkServiceabilityModel = {
    address,
    affiliateID,
    promoCode: businessAddress ? businessPromoCode : promoCode,
    partner: config.RESIDENTIAL_PARTNER,
    keyFlags: 'extended_output'
  }
  if (suggestedAddress) {
    checkServiceabilityModel.suggestedAddress = suggestedAddress
  }
  return checkServiceabilityModel;
}

export const getServiceCheckModel = (
  address,
  address1,
  address2,
  city,
  state,
  zip,
  agentId,
  productList,
  nbcScore
) => {
  let serviceCheckModel = {
    address,
    address1,
    address2,
    city,
    state,
    zip,
    agentId,
    productList,
    nbcScore,
  };
  return serviceCheckModel;
};

export const getAccountCheckModel = (firstName, lastName, accountEmail) => {
  let accountCheckModel = {
    firstName,
    lastName,
    accountEmail: accountEmail.split('@')[0],
    domain: accountEmail.split('@')[1] || 'earthlink.net',
  }
  return accountCheckModel;
};

export const serialize = (obj) => {
  let str = Object.keys(obj)
    .reduce(function (a, k) {
      a.push(k + '=' + encodeURIComponent(obj[k]));
      return a;
    }, [])
    .join('&');
  return str;
};

export const getOrderSubmissionModel = ({
  accountEmail,
  affiliateId,
  agentReadTC,
  answer,
  billingAddress,
  creditCardCSV,
  creditCardExp,
  creditCardNum,
  customerAcceptedTC,
  email,
  firstName,
  lastName,
  orderId,
  password,
  phoneNum,
  promoCode,
  question,
  salesAgentId,
  serviceAddress,
  serviceReference,
  isExpressContainer,
  installWindow,
  isElnkMailboxReq,
  installPhone,
  TCPADisclaimer,
  bundleCode,
  languagePref,
}) => {
  // const { question, answer } = getSecurityQuestion();
  const billingAddr = getSegregatedAddress(billingAddress);
  const isOptMail = isElnkMailboxReq && accountEmail && accountEmail.includes('@earthlink.net') ? true : isElnkMailboxReq;
  const createPrimary = isOptMail ? { accountEmail: accountEmail?.split('@')[0], domainName: accountEmail?.split('@')[1], } : { createPrimary: 'no' };
  const isCqasAdded = config.SHOW_PSWD_CQAS === 'true' ? {
    password,
    firstSecurityAnswer: answer,
    firstSecurityQuestion: question,
  } : {};
  let orderSubmissionModel = {};
  const tcpaInfo = TCPADisclaimer ? {
    tcpaMktPhone: phoneNum.replace(/\D/g, '')?.slice(-10),
    cpaMktOptInSms: 'yes',
    tcpaMktOptInCall: 'yes',
    tcpaEmail: email,
    tcpaNewslettersOptInEmail: 'yes',
    tcpaProdUpdatesOptInEmail: 'yes',
  } : {}
  if (isExpressContainer) {
    orderSubmissionModel = {
      affiliateId,
      broadbandOptionalFields: {
        salesAgentId,
        serviceName: 'elnkafil', // it will remain constant for all www traffic
        serviceReference,
      },
      billingAddress: {
        billingAddress1: billingAddr.address1,
        billingAddress2: billingAddr.address2,
        billingCity: billingAddr.city,
        billingState: billingAddr.state,
        billingZip: billingAddr.zip,
      },
      ...tcpaInfo,
      installPhone: installPhone || '',
      regInfo: {
        ...createPrimary,
        ...isCqasAdded,
        firstName,
        lastName,
        phoneNum,
        creditCardNum,
        creditCardExp,
        creditCardCSV,
        agentReadTC,
        customerAcceptedTC,
        languagePref: languagePref ? 'spanish' : 'english',
      },
      email,
      orderId,
      promoCode,
      isExpressContainer,
    }
  } else {
    const serviceAddr = getSegregatedAddress(serviceAddress);

    orderSubmissionModel = {
      callLogId: getCallLogId(),
      affiliateId,
      broadbandOptionalFields: {
        salesAgentId,
        serviceName: 'elnkafil', // it will remain constant for all www traffic
        serviceReference,
      },
      billingAddress: {
        billingAddress1: billingAddr.address1,
        billingAddress2: billingAddr.address2,
        billingCity: billingAddr.city,
        billingState: billingAddr.state,
        billingZip: billingAddr.zip,
      },
      ...tcpaInfo,
      installPhone: installPhone || '',
      regInfo: {
        ...createPrimary,
        ...isCqasAdded,
        firstName,
        lastName,
        serviceAddress1: serviceAddr.address1,
        serviceAddress2: serviceAddr.address2,
        serviceCity: serviceAddr.city,
        serviceState: serviceAddr.state,
        serviceZip: serviceAddr.zip,
        phoneNum,
        languagePref: languagePref ? 'spanish' : 'english',
        creditCardNum,
        creditCardExp,
        creditCardCSV,
        agentReadTC,
        customerAcceptedTC,
        ...bundleCode,
      },
      installWindow: installWindow && installWindow.length > 0 ? installWindow : '',
      email,
      orderId,
      promoCode,
    };
  }
  return orderSubmissionModel;
};

export const getCreateAccountInfoModel = (
  email,
  firstSecurityQuestion,
  firstSecurityAnswer,
  isElnkMailboxReq
) => {
  let createAccountInfoModel = {
    accountEmail: email.indexOf('@') === -1 ? `${email}@earthlink.net` : email,
    orderStatus: OrderStatus.acctEmail,
  };
  if (firstSecurityQuestion && firstSecurityAnswer) {
    createAccountInfoModel.firstSecurityQuestion = SecQueOptions[firstSecurityQuestion]
    createAccountInfoModel.firstSecurityAnswer = firstSecurityAnswer
  }
  if (!isElnkMailboxReq) {
    createAccountInfoModel.email = email;
  }
  return createAccountInfoModel;
};

export const getPaymentInfoModel = ({
  agentReadTC,
  customerAcceptedTC,
  maskedCcn,
  ccn,
  encryptedCcn,
  maskedCvc,
  cvc,
  encryptedCvc,
  cardHolderName,
  expirationDate,
  integrityCheck,
  paymentType
}) => {
  let paymentInfoModel = {
    agentReadTC,
    customerAcceptedTC,
    maskedCcn,
    ccn,
    encryptedCcn,
    maskedCvc,
    cvc,
    encryptedCvc,
    cardHolderName,
    expirationDate,
    integrityCheck,
    paymentType,
  };
  return paymentInfoModel;
};

export const TRANSLATE = {
  english: {
    installationCost: 'Installation Cost',
    processingAndHandlingFee: 'Processing & Handling Fee',
    shippingCost: 'Shipping Cost',
    monthlyCharges: 'Monthly Charges',
    modemLease: 'Modem Lease',
    plusApplicableTaxes: 'Plus Applicable Taxes and Fees',
    oneTimeCharges: 'One-time Charges',
    orderDetails: 'Order Details',
    thanku: 'Thank you',
    thanksMsg: 'Thank you for your order. If you have any questions about your order and next steps, please call',
    confirmationNumber: 'Confirmation Number',
    accountNumber: 'Account Number',
    mobile: 'Mobile',
    orderDetailsTotal: 'Today',
    orderDetailsMonthly: 'Monthly',
    SIMCardFee: 'SIM Card Fee',
    Mobilenotcharged: 'Mobile not charged until Activation',
    billingAddress: 'Billing Address',
    bundleDiscount: 'Bundle Discount Applied',
    firstMonth: 'First Month',
    emailAddress: 'Email Address',
    monthly: 'Monthly',
    today: 'Today',
    thankuAndWelcome: 'Thank you for your order and Welcome to the EarthLink family',
    welcomeEmail : 'a Welcome email to help you complete your account registration.',
    text1 : 'Two important communications coming your way',
    text12 : 'an email and a text (to phone number ending in',
    text13 : 'regarding your installation and',
    text14 : 'So please be on the lookout for those.  If you have any questions about your order or next steps, please ',
    text15 : 'chat with us',
    text16 : 'or call us at',
  },
  spanish: {
    installationCost: 'Costo de instalacion',
    processingAndHandlingFee: 'Tarifa de procesamiento y manejo',
    shippingCost: 'Costo de envío',
    monthlyCharges: 'Cargos mensuales',
    modemLease: 'Renta del módem',
    plusApplicableTaxes: 'Más impuestos y tarifas aplicables',
    oneTimeCharges: 'Cargos de una vez',
    orderDetails: 'Detalles del pedido',
    thanku: 'Gracias',
    thanksMsg: 'Gracias por su orden. Si tiene alguna pregunta sobre su pedido y los próximos pasos, llame al',
    confirmationNumber: 'Número de confirmación',
    accountNumber: 'Número de cuenta',
    mobile: 'Móvil',
    orderDetailsTotal: 'Hoy',
    orderDetailsMonthly: 'Mensual',
    SIMCardFee: 'Tarifa de la tarjeta SIM',
    Mobilenotcharged: 'Móvil no cargada hasta la activación',
    billingAddress: 'Dirección de Envio',
    bundleDiscount: 'Descuento por paquete aplicado',
    firstMonth: 'Primer Mes',
    thankuAndWelcome: 'Gracias por su pedido y bienvenido a la familia EarthLink',
    welcomeEmail: 'Un correo electrónico de bienvenida para ayudarlo a completar el registro de su cuenta',
    text1 : 'Dos noticias importantes están en camino',
    text12 : 'Un correo electrónico y un mensaje de texto (al número de teléfono que termina en',
    text13 : 'con respecto a su instalación',
    text14 : 'Así que por favor esté atento a su correo electrónico y a sus mensajes de texto. Si tiene alguna pregunta sobre su pedido o los próximos pasos,',
    text15 : 'chateé con nosotros',
    text16 : 'o llámanos al',
    emailAddress: 'Dirección de correo electrónico',
    monthly: 'Mensual',
    today:'Hoy',
  },
};

export const getURI = (url) => {
  const [, ...segments] = url.replace(/http(s)?:\/\//gm, '').split('/');
  return `/${segments?.join('/').replace('//', '/')}`;
};
