import React, { useEffect, useState } from 'react';
import { Collapse, Row, Col, Modal } from 'antd';
import CartInformation from '../../common/CartInformation';
import CompatibilityCheck from './CompatibilityCheck';
import EnterInformation from './EnterInformation';
import PaymentDetail from './PaymentDetail'
import { useCartData, useLoading, useMbox, useServiceablitityData, useSessionData } from '../../../hooks/order';
import { useDispatch } from 'react-redux';
import { CheckScheduleAppt, resetState, saveInternetPlan, saveScheduleDateTime, saveWirelessPlanInfo, setActiveProductTab, setRtsVisible, setSelectedBundle, setTFN } from '../../../store/action/OrderAction';
import { getAggregatedAddress, getCallLogId, getHomeUrl, getSessionOrderId } from '../../../common/common';
import CheckoutHeadText from './CheckoutHeadText';
import { BackdropLoader } from '../../common/Loader';
import { MOBILE_ABND_TFN, WHI_ABND_TFN, routesConfig } from '../../../util/util';
import { getConfigs } from '../../../config';
import CreateAccount from './CreateAccount';
import { useNavigate } from 'react-router-dom';
import ScheduleInstallation from './ScheduleInstallation';

const config = getConfigs();

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sessionResponse } = useSessionData();
  const { isInternetAdded, internetPlan, isMobileAdded, isProductsAdded, isVasAdded, isBundleAdded, vasPlanInfo, wirelessCartInfo, updateSelectedBundle, servRef, isWhiAdded } = useCartData();
  const { scheduleAppt, canScheduleAppt, serviceabilityCheck, normalizedAddress } = useServiceablitityData();
  const { plans = [], mobileProducts = [], fixedWirelessProducts = [], bundlesDetails } = serviceabilityCheck;
  const { scheduleLoading } = useLoading();
  const { Panel } = Collapse;
  const callLogId = getCallLogId();
  const SHOW_SCHEDULE_APPT = config.SHOW_SCHEDULE_APPT && config.SHOW_SCHEDULE_APPT.toLowerCase() === 'true';
  const showSchedule = isInternetAdded && SHOW_SCHEDULE_APPT && canScheduleAppt === 'yes';
  const [activeKey, setActiveKey] = useState('1');
  const orderId = getSessionOrderId();
  const { isElnkMailboxReq } = useMbox();
  const [showCallSupportModal, setShowCallSupportModal] = useState(false);
  const childRef = React.createRef();

  useEffect(() => {
    if (!isProductsAdded || Object.keys(sessionResponse).length === 0 || !orderId) {
      window.location.href = getHomeUrl();
    }

    if (!plans.length && !mobileProducts.length && fixedWirelessProducts.length) {
      dispatch(setTFN(WHI_ABND_TFN));
    } else if (!isInternetAdded) {
      dispatch(setTFN(MOBILE_ABND_TFN));
    } else {
      dispatch(setTFN('ABNDCART'));
    }
    if (showSchedule) {
      dispatch(CheckScheduleAppt({
        userName: 'xmlc',
        serviceReference: internetPlan.servRef,
        callLogId
      })).then(res => {
        if (res.value.data.installWindow && res.value.data.installWindow.length > 0) {
          setActiveKey('1');
        } else {
          setActiveKey(isMobileAdded ? '2' : '3');
          if (!isWhiAdded) {
            dispatch(setRtsVisible(true));
          }
        }
      }).catch(() => setActiveKey('2'));
    } else {
      setActiveKey(isMobileAdded ? '2' : '3');
    }
  }, []);

  // create bundle when products selected individually
  useEffect(() => {
    updateSelectedBundle();
  }, [
    isInternetAdded,
    isMobileAdded,
    isVasAdded,
    isBundleAdded,
    bundlesDetails,
    dispatch,
    internetPlan,
    wirelessCartInfo,
    vasPlanInfo,
    servRef
  ]);

  const handleModalClose = () => {
    setShowCallSupportModal(false);
    dispatch(resetState());
    window.location.href = config.BASE_URL;
  }

  const handleEditEmail = () => {
    childRef.current.emailEdit();
  }

  if (scheduleLoading) {
    return <BackdropLoader />
  }

  const showScheduleTile = showSchedule && scheduleAppt && scheduleAppt.length > 0 ? true : false;
  const showCreateAccTile = config.SHOW_PSWD_CQAS === 'true' ? true : isElnkMailboxReq;

  const handleRestAddress = () => {
    dispatch(saveInternetPlan({}))
    dispatch(saveWirelessPlanInfo({
      wirelessPlanInfo: [],
      wirelesServeRef: ''
    }));
    dispatch(setActiveProductTab(''))
    dispatch(setSelectedBundle(null));
    navigate(routesConfig.home)
    dispatch(saveScheduleDateTime(''));
  }

  return (
    <>
      <div className="elink-container p-top-5 height-big-screen">
        <div className="result-heading">
          <CheckoutHeadText />
          <p>Let&apos;s get you connected at <b>{getAggregatedAddress(normalizedAddress)}</b></p>
          <button onClick={() => handleRestAddress()}>Change Address</button>
        </div>
        <div className="main-checkout">
          <Row>
            <Col span={16} className="p-right-1 responsive-width-set">
              <Collapse accordion defaultActiveKey={['1']} activeKey={activeKey} className="checkout-accordion">
                {showScheduleTile &&
                  <Panel header={
                    <>
                      <span>Schedule Internet Installation</span>
                      {parseInt(activeKey) > 1 &&
                        <button className="checkout_edit" onClick={() => setActiveKey(1)} onKeyDown={() => { }}>
                          Edit
                        </button>}
                    </>
                  } className={`pnl ${parseInt(activeKey) > 1 && 'checkout_span_ok'}`} key="1">
                    <ScheduleInstallation scheduleAppt={scheduleAppt} activeKey={activeKey} setActiveKey={setActiveKey} isMobileAdded={isMobileAdded} />
                  </Panel>
                }
                {isMobileAdded && <Panel header={
                  <>
                    <span>Device Compatibility Check</span>
                    {parseInt(activeKey) > 2 &&
                      <span>
                        <button className="fas fa-edit" onClick={() => setActiveKey(2)} onKeyDown={() => { }}></button>
                      </span>}
                  </>
                } className="pnl" key="2">
                  <CompatibilityCheck activeKey={activeKey} setActiveKey={setActiveKey} />
                </Panel>}
                <Panel header={
                  <>
                    <span>Enter Your Information</span>
                    {parseInt(activeKey) > 3 &&
                      <button className="checkout_edit" onClick={() => setActiveKey(3)} onKeyDown={() => { }}>
                        Edit
                      </button>}
                  </>
                } className={`pnl ${parseInt(activeKey) > 3 && 'checkout_span_ok'}`} key="3">
                  <EnterInformation ref={childRef} activeKey={activeKey} setActiveKey={setActiveKey} setShowCallSupportModal={setShowCallSupportModal} showCreateAccTile={showCreateAccTile} />
                </Panel>
                {showCreateAccTile && <Panel
                  header={
                    <>
                      <span>Create Account</span>
                      {parseInt(activeKey) > 4 &&
                        <button className="checkout_edit" onClick={() => setActiveKey(4)} onKeyDown={() => { }}>
                          Edit
                        </button>}
                    </>
                  } className={`pnl ${parseInt(activeKey) > 4 && 'checkout_span_ok'}`} key="4">
                  <CreateAccount ref={childRef} activeKey={activeKey} setActiveKey={setActiveKey} setShowCallSupportModal={setShowCallSupportModal} />
                </Panel>}
                <Panel
                  header={
                    <>
                      <span>Payment Details</span>
                      {parseInt(activeKey) > 5 &&
                        <button className="checkout_edit" onClick={() => setActiveKey(showCreateAccTile ? 5 : 4)} onKeyDown={() => { }}>
                          Edit
                        </button>}
                    </>
                  } className="pnl" key={`${showCreateAccTile ? '5' : '4'}`}>
                  <PaymentDetail activeKey={activeKey} setActiveKey={setActiveKey} />
                </Panel>
              </Collapse>
            </Col>
            <Col span={8} className="p-left-1">
              <CartInformation />
            </Col>
          </Row>
        </div>
      </div>
      <Modal title="Account Already Exists" className="model-title" cancelText="Cancel, try another Email" open={showCallSupportModal} onOk={handleModalClose} onCancel={handleEditEmail}>
        <div className="modal-body">
          We found existing EarthLink services tied to this email address.  If you are an existing Customer, please contact <a href="https://www.earthlink.net/about-us/contact/" target="_blank" rel="noopener noreferrer" style={{ color: '#007dba' }}> Support</a>. Or enter a different Email address to continue.
        </div>
      </Modal>
    </>
  )
}
export default Checkout;

