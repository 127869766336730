import React, { useState, useEffect } from 'react';
import { getAggregatedAddress } from '../../common/common';
import { Select } from 'antd';
import { routesConfig } from '../../util/util';
import { BackdropLoader } from '../common/Loader';
import { useLoading, useServiceablitityData } from '../../hooks/order';
import { useNavigate } from 'react-router-dom';

const { Option } = Select;

const AddressSuggestions = (props) => {
  const navigate = useNavigate();
  const { serviceabilityCheck, normalizedAddress } = useServiceablitityData();
  const { loading } = useLoading();
  const { suggestedAddresses, plans } = serviceabilityCheck;
  const [addresses, setAddresses] = useState(suggestedAddresses);

  useEffect(() => {
    setAddresses(suggestedAddresses);
  }, [suggestedAddresses]);

  useEffect(() => {
    if (plans.length > 0) {
      return navigate(routesConfig.home);
    }
  }, [plans]);

  const handleChange = (value) => {
    const suggestedAddress = addresses[value];
    const address = getAggregatedAddress(normalizedAddress);
    if (value < addresses.length) {
      props.onAddressClick(address, suggestedAddress);
    } else {
      props.onNotSeenClick();
    }
  };

  if (loading) {
    return <BackdropLoader />;
  }

  return (
    <>
      <div className="elink-container mt-5 serviceabilityCheck">
        <div className="card">
          <div className="inner-card">
            <div>
              <h2 className="text-center">Please specify your Address</h2>
              <div>
                <Select
                  autoFocus={false}
                  className={props.className}
                  showAction={['focus', 'click']}
                  showArrow={true}
                  size="large"
                  onChange={handleChange}
                  placeholder="Select Address"
                >
                  {addresses.length &&
                    addresses.map((addr, i) => {
                      const address = getAggregatedAddress(addr);
                      return (
                        <Option className="suggestedAddr" value={i} key={i}>
                          {address}
                        </Option>
                      );
                    })}
                  <Option
                    className="addressNotListed"
                    key={addresses.length}
                    value={addresses.length}
                  >
                    I don&apos;t see my address listed.
                  </Option>
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddressSuggestions;
