/* eslint-disable no-console */
import React from 'react';
import { Calendar } from '../../common/DateFnsWrapper';
import { format, parseISO, isSameDay } from 'date-fns';
import { Row, Col } from 'antd';
import { parseDates } from '../../util/util';

const ScheduleInstallationCalendar = ({
  calenderSelectedDate,
  getTimeSlots,
  scheduleState,
  setScheduleState,
  getInitialDate,
  dateTimeSlots,
}) => {
  const onSelect = (date) => {
    const selectedDate = format(date, 'yyyy-MM-dd'); // Format the date using date-fns
    setScheduleState({
      ...scheduleState,
      timeSlots: getTimeSlots(selectedDate), // Call getTimeSlots with the formatted date
      calenderSelectedDate: selectedDate,
      isSelectedTime: '',
    });
  };

  const disabledDate = (current) => {
    const disabledDates = Object.keys(dateTimeSlots).find((o) => {
      return isSameDay(parseISO(o), current);
    });

    return !disabledDates;
  };

  return (
    <div className="calendar-container">
      <Calendar
        headerRender={({ value, onChange }) => {
          let start =
            Number(parseDates(Object.keys(dateTimeSlots)[0], 'MM')) - 1;
          const end = Number(
            parseDates(
              Object.keys(dateTimeSlots)[Object.keys(dateTimeSlots).length - 1],
              'MM'
            )
          );
          const monthOptions = [];
          if (start > end) {
            for (let index = start; index < 12; index++) {
              monthOptions.push(index);
            }
            start = 0;
          }
          for (let index = start; index < end; index++) {
            monthOptions.push(index);
          }
          const month = format(value, 'MMMM');

          const handleChangeMonth = (op) => {
            let selectedMonth;
            const newValue = new Date(value);

            if (op === '+') {
              selectedMonth = (newValue.getMonth() + 1) % 12;
            } else if (op === '-') {
              selectedMonth = newValue.getMonth()
                ? (newValue.getMonth() % 12) - 1
                : 11;
            } else if (op === 'last') {
              selectedMonth = monthOptions[monthOptions.length - 1];
            } else if (op === 'first') {
              selectedMonth = monthOptions[0];
            }

            if (selectedMonth < 0 || selectedMonth > 11) {
              console.error('Invalid month selected:', selectedMonth);
              return;
            }

            if (monthOptions.includes(selectedMonth)) {
              newValue.setMonth(selectedMonth);
              onChange(newValue);

              const initialDate = getInitialDate(selectedMonth + 1);
              if (!Date.parse(initialDate)) {
                console.error('Invalid initial date:', initialDate);
                return;
              }
              onSelect(parseISO(initialDate));
            }
          };

          return (
            <div>
              <Row className="schedule-header mb-2">
                <Col span={5}>Month: &nbsp; </Col>
                <Col span={1}>
                  <i
                    className={`fa-solid fa-angles-left ${
                      value.getMonth() === monthOptions[0]
                        ? 'hide-items'
                        : 'titleColor show-items'
                    }`}
                    aria-hidden="true"
                    onClick={() => handleChangeMonth('first')}
                  ></i>
                </Col>
                <Col span={1}></Col>
                <Col span={1}>
                  <i
                    className={`fa-solid fa-angle-left ${
                      value.getMonth() === monthOptions[0]
                        ? 'hide-items'
                        : 'titleColor show-items'
                    }`}
                    aria-hidden="true"
                    onClick={() => handleChangeMonth('-')}
                  ></i>
                </Col>
                <Col span={10} className="text-center">
                  <span>{month}</span>
                </Col>
                <Col span={1}>
                  <i
                    className={`fa-solid fa-angle-right ${
                      value.getMonth() === monthOptions[monthOptions.length - 1]
                        ? 'hide-items'
                        : 'titleColor show-items'
                    }`}
                    aria-hidden="true"
                    onClick={() => handleChangeMonth('+')}
                  ></i>
                </Col>
                <Col span={1}></Col>
                <Col span={1}>
                  <i
                    className={`fa-solid fa-angles-right ${
                      value.getMonth() === monthOptions[monthOptions.length - 1]
                        ? 'hide-items'
                        : 'titleColor show-items'
                    }`}
                    aria-hidden="true"
                    onClick={() => handleChangeMonth('last')}
                  ></i>
                </Col>
              </Row>
            </div>
          );
        }}
        value={parseISO(calenderSelectedDate)}
        onSelect={onSelect}
        disabledDate={disabledDate}
        fullscreen={false}
      />
    </div>
  );
};

export default ScheduleInstallationCalendar;
