import React, { useEffect } from 'react';
import queryString from 'query-string';

import AppRoutes from './routes/Routes';
import { getConfigs } from './config';
import { ConfigProvider } from 'antd';
function App() {
  const config = getConfigs();
  const [,search] = window.location.href.split('?');
  const { dniId = '' } = queryString.parse(search);

  // Redirect partner requests to hash url if necessary
  useEffect(() => {
    if (window.location.hash === '' && window.location.pathname.startsWith('/p/')) {
      const [,uri] = window.location.href.split('/p/');

      window.location.href = `/#/partner/${uri}`;
    }
  }, []);

  // Load DNI script
  useEffect(() => {
    const dniConfigs = ['window.elnkDNIConfigs = [];', 'elnkDNIConfigs.push(["replaceMode", "dom"]);'];

    if (dniId) {
      dniConfigs.push(`elnkDNIConfigs.push(['dniId', '${dniId}']);`);
    }

    const script = document.createElement('script');
    script.src = config.DNI_API_SCRIPT_SRC;
    script.async = true;
    document.head.appendChild(script);
    const scriptConfig = document.createElement('script');
    scriptConfig.text = dniConfigs.join('\n');
    document.head.appendChild(scriptConfig);

    return () => {
      document.body.removeChild(script);
    };
  },[]);

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: ['Montserrat','sans-serif'],
          margin: '0px',
        },
        component: {
          formItem: {
            labelWidth: 100,
          },
        },
      }}>
      <AppRoutes />
    </ConfigProvider>

  );
}

export default App;
