import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef, useMemo } from 'react';
import { Form, Input, Row, Col, Select } from 'antd';
import { getValidAcctEmail, handleAlphaKeyPress, isUndefinedNullOrEmpty } from '../../../common/common';
import { getAccountCheckModel, getCreateAccountInfoModel } from '../../../helpers/OrderHelperFunctions';
import { useDispatch } from 'react-redux';
import { useAccountCheck, useLoading, useCartData, useMbox, useSessionData } from '../../../hooks/order';
import { accountCheck, saveChgQuestionInfo, savePassword, updateErrorMsg, updateOrder } from '../../../store/action/OrderAction';
import { ValidationMessages } from '../../../common/validationMessages';
import { SecQueOptions } from '../../../util/util';
import { emailIdRegex, lowerCaseRegex, numberRegex, specialCharRegex, uppercaseRegex } from '../../../util/regex';
import { Loader } from '../../common/Loader';
import { getConfigs } from '../../../config';
const config = getConfigs();

const CreateAccount = forwardRef((props, ref) => {
  const [form] = Form.useForm();
  const emailInp = useRef();
  const dispatch = useDispatch();
  const { loading } = useLoading();
  const { checkAccount, checkingAccount } = useAccountCheck();
  const { orderInfo } = useSessionData();
  const { isElnkMailboxReq } = useMbox();
  const { id, firstName, lastName, email } = orderInfo;
  const [emailInvalidMsg, setEmailInvalidMsg] = useState('');
  const [emailIsAvailable, setEmailIsAvailable] = useState('');
  const [suggestedIds, setSuggestedIds] = useState([]);
  const [submittable, setSubmittable] = useState(false);
  const { vasPlan } = useCartData();
  const { Option } = Select;
  const valuesStatus = Form.useWatch([], form);
  const showELNKAccountCreationFields = useMemo(() => {
    return config.SHOW_PSWD_CQAS === 'true' || !!vasPlan.find((i) => i.servCode === 'onlnbckp');
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      accountEmail: !isUndefinedNullOrEmpty(orderInfo.accountEmail) ?
        getValidAcctEmail(orderInfo.accountEmail, isElnkMailboxReq) :
        isUndefinedNullOrEmpty(orderInfo.email) ?
          '' :
          getValidAcctEmail(orderInfo.email, isElnkMailboxReq)
    })
    checkAcctAvail();
  }, [email, isElnkMailboxReq])

  // Watch all values
  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, valuesStatus]);

  const onFinish = (values) => {
    const { accountEmail, question = '', answer = '', password } = values;
    // IF USER ID AVAILABLE THEN UPDATE ORDER
    if (!isUndefinedNullOrEmpty(id) && emailIsAvailable) {
      const accountInfoModel = getCreateAccountInfoModel(accountEmail, question, answer, isElnkMailboxReq)
      if (props.showCreateAccTile) {
        dispatch(savePassword(password));
        dispatch(saveChgQuestionInfo({
          question: SecQueOptions[question],
          answer: answer.trim(),
        }));
      }
      dispatch(updateOrder(accountInfoModel, id)).then(() => {
        props.setActiveKey('5');
      }).catch((err) => {
        dispatch(updateErrorMsg(err))
      });
    }
  }

  const accError = form.getFieldError('accountEmail');
  const accountEmail = form.getFieldValue('accountEmail');

  const checkAcctAvail = () => {
    const accEmail = form.getFieldValue('accountEmail');
    const err = form.getFieldError('accountEmail')
    if (accEmail && !isElnkMailboxReq) {
      if (accEmail && !isElnkMailboxReq && emailIdRegex.test(accEmail)) {
        form.setFields([
          {
            name: 'accountEmail',
            errors: [ValidationMessages.invalidEmail],
          },
        ]);
        return;
      }
    }
    if (accEmail && accEmail.length >= 3 && err?.length === 0) {
      if (!isUndefinedNullOrEmpty(accEmail) && !isUndefinedNullOrEmpty(firstName) && !isUndefinedNullOrEmpty(lastName)) {
        const accountCheckModel = getAccountCheckModel(firstName, lastName, accEmail);
        dispatch(accountCheck(accountCheckModel)).then(() => {
          if (checkAccount && checkAccount.emailIsAvailable) {
            setEmailIsAvailable(true);
            setEmailInvalidMsg('');
          } else {
            if (isElnkMailboxReq) {
              setEmailIsAvailable(false);
              setEmailInvalidMsg('ID is unavailable. Please enter a different ID.');
              setSuggestedIds(checkAccount?.suggestedIds);
            } else {
              props.setShowCallSupportModal(true);
            }
          }
        }).catch(() => {
          if (isElnkMailboxReq) {
            setEmailIsAvailable(false);
            setEmailInvalidMsg('Try after some time.');
            setSuggestedIds(suggestedIds);
          } else {
            props.setShowCallSupportModal(true);
          }
        })
      }
    }
  }

  const handleSuggestionClick = (data) => {
    form.setFieldsValue({
      accountEmail: data
    });
    setEmailIsAvailable(true);
  }

  useImperativeHandle(ref, () => ({
    emailEdit() {
      handleEditEmail();
    },
  }));

  const handleEditEmail = () => {
    props.setShowCallSupportModal(false);
    form.setFieldsValue({
      accountEmail: ''
    });
    emailInp.current.input.focus();
    return;
  }
  return (
    <>
      <div className="create-account same-common label">
        <Form
          layout="vertical"
          name="basic"
          initialValues={{ remember: true }}
          form={form}
          onFinish={onFinish}
          validateTrigger={'onBlur'}
        >
          <Row className="responsive-width-n">
            <Col span={19}>
              <Form.Item
                label="Email"
                className="input-type-span"
                name="accountEmail"
                rules={[{ required: true, message: 'Please enter Email' }, {
                  min: 3,
                  message: ValidationMessages.min3Length
                },
                {
                  validator(_, value) {
                    if (!isUndefinedNullOrEmpty(value)) {
                      if (!isElnkMailboxReq && emailIdRegex.test(value)) {
                        return Promise.reject(new Error(ValidationMessages.invalidEmail))
                      }
                    }
                    return Promise.resolve();
                  }
                }]}
              >
                <Input onBlur={checkAcctAvail} ref={emailInp} onChange={() => {
                  setEmailIsAvailable(false);
                  setEmailInvalidMsg('');
                  setSuggestedIds([]);
                }} />
              </Form.Item>
              {isElnkMailboxReq && <span className="input-span">@earthlink.net</span>}
            </Col>
            <Col span={1} className="responsive-width-set"></Col>
            {
              !checkingAccount
                ?
                <Col span={4} className="responsive-width-set">
                  <button className="Check-account blue-button"
                    onClick={checkAcctAvail}
                    disabled={(accountEmail && accountEmail.length <= 3) || emailIsAvailable || accError.length > 0}
                  >
                    Check
                  </button>
                </Col>
                :
                <div className={'accountLoading check-loading-reponsive text-center'}>
                  <Loader />
                </div>
            }
          </Row>
          {
            !emailIsAvailable && emailInvalidMsg !== '' &&
            <p className="red-color email-suggest-msg">{emailInvalidMsg}</p>
          }
          {
            !emailIsAvailable && suggestedIds.length > 0 ?
              <div className="suggestedIds">
                <p className="red-color id-suggest-msg">Suggested IDs:</p>
                <ul className="suggestionsContainer">
                  {
                    suggestedIds.map((suggestId, i) => {
                      return (
                        <li key={i} className="suggestions" onClick={() => handleSuggestionClick(suggestId)}>{suggestId}</li>
                      )
                    })
                  }
                </ul>
              </div>
              : ''
          }

          {showELNKAccountCreationFields && <>
            <Row>
              <Col span={24} className="responsive-width-set">
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[{ required: true, message: 'Please enter Password' }, {
                    validator(_, value) {
                      if (!isUndefinedNullOrEmpty(value)) {
                        let validateCondition = 0
                        if (value.match(specialCharRegex) && value.match(specialCharRegex).length > 0) {
                          validateCondition += 1;
                        }
                        if (value.match(uppercaseRegex) && value.match(uppercaseRegex).length > 0) {
                          validateCondition += 1;
                        }
                        if (value.match(lowerCaseRegex) && value.match(lowerCaseRegex).length > 0) {
                          validateCondition += 1;
                        }
                        if (value.match(numberRegex) && value.match(numberRegex).length > 0) {
                          validateCondition += 1;
                        }
                        if (validateCondition < 3 || value.length < 8 || value.length > 32) {
                          return Promise.reject(new Error(`${ValidationMessages.passwordInvalid}`))
                        }
                        if (value.toLowerCase().includes(firstName.toLowerCase()) || value.toLowerCase().includes(lastName.toLowerCase())) {
                          return Promise.reject(new Error('Password should not have name'))
                        }
                      }
                      return Promise.resolve();
                    }
                  }]}
                >
                  <Input.Password autoComplete="new-password" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Repeat Password"
                  dependencies={['password']}
                  name="RepeatPassword"
                  rules={[{ required: true, message: 'Please enter Repeat Password' },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Passwords do not match'));
                      },
                    }),]}
                >
                  <Input.Password />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12} className="your-info-input responsive-width-set">
                <Form.Item
                  label="Select A Question"
                  name="question"
                  rules={[{ required: true, message: 'Please select security question' }]}
                >
                  <Select
                    placeholder="Select A Question"
                    dropdownMatchSelectWidth={false}
                  >
                    {
                      SecQueOptions.map((option, i) => {
                        return <Option key={i} value={i}>{option}</Option>
                      })
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={1}></Col>
              <Col span={11} className="your-info-input responsive-width-set">
                <Form.Item
                  label="Answer"
                  name="answer"
                  validateFirst={true}
                  rules={[{
                    required: true,
                    whitespace: true,
                    message: 'Please enter Answer'
                  },
                  {
                    min: 2,
                    message: `${ValidationMessages.min2Length}`
                  },
                  ]}
                >
                  <Input onKeyPress={handleAlphaKeyPress} />
                </Form.Item>
              </Col>
            </Row>
          </>}
          <Row>
            <Col span={24} className="your-info-tab">
              <div className="text-right enter-your-info">
                {loading ?
                  <Loader />
                  :
                  <button className="blue-button" disabled={!submittable} type="submit">Continue</button>
                }
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  )
});
export default CreateAccount;
