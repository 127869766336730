import React from 'react'
import { Modal } from 'antd'
import PropTypes from 'prop-types';

const WhiModal = ({ showWhiAddedModal, setShowWhiAddedModal, selectedProduct, handleConfirmOrder, handleAddIntToCart }) => {
  const isReviewOrderPage = window.location.href.includes('review-order');

  const handleOnOk = () => {
    if (isReviewOrderPage) {
      handleConfirmOrder();
    } else {
      handleAddIntToCart(selectedProduct);
    }
  }

  return (
    <Modal
      open={showWhiAddedModal}
      onOk={() => {
        setShowWhiAddedModal(false);
        handleOnOk();
      }}
      onCancel={() => {
        setShowWhiAddedModal(false);
      }}
      className="whi-modal"
      width={800}
      closable={selectedProduct?.shortName === 'WFH Unlimited 300' }
      closeIcon={
        selectedProduct?.shortName === 'WFH Unlimited 300' && <span className="closeable-btn">&times;</span> // Custom close button with style
      }
      cancelButtonProps={selectedProduct?.shortName === 'WFH Unlimited 300' && {
        style: {
          display: 'none'
        }
      }}
      okButtonProps={{
        style: {
          margin: '10px'
        }
      }}
    >
      <div>
        {selectedProduct?.shortName !== 'WFH Unlimited 300' ? <p className="heading">Before purchasing Wireless Home Internet, you acknowledge your understanding of the following:</p> :
          <p className="heading" style={{ marginTop: '20px' }}> To qualify for this plan, you confirm that you work from home on a full, part time or occasional basis or own a small business.  Does this describe you and your intended use?</p>
        }
        {selectedProduct?.shortName !== 'WFH Unlimited 300' ? <ul>
          <li>
            Wireless Home Internet plans offer a fixed data allotment and are not unlimited. Once data has been consumed, access to the internet will be temporarily suspended until your next billing cycle, unless you upgrade plans or purchase additional data
          </li>
          <li>
            To purchase Wireless Home Internet, you will need to pay a nonrefundable $79.95 Processing & Handling Fee
          </li>
          <li>
            To activate Wireless Home Internet services, you will need to pay your first month’s bill. This is separate from the nonrefundable Processing & Handling Fee
          </li>
          <li>
            If your Service has not been activated within 10 days from the Equipment delivery date, your Service will be automatically activated by EarthLink and the primary method of payment on file will be charged for the first month’s invoice, plus applicable taxes and fees. Upon activation, Subscribers will have access to the internet. If EarthLink is unable to activate Wireless Home Internet Services, EarthLink reserves the right to cancel such Services.
          </li>
        </ul> :
          <ul>
            <li>This Plan is eligible for those that work from home (full, part time, occasionally) or own a small business.</li>
            <li>The Work From Home (WFH) Unlimited Wireless Home Internet Plan offers 300 gigabytes of high-speed data.  Once all high-speed data is consumed, the WFH Unlimited Plan offers unlimited standard-speed data. </li>
            <li>
              To activate Wireless Home Internet services, you will need to pay your first month’s bill. This is separate from the nonrefundable Processing & Handling Fee
            </li>
            <li>
              If your Service has not been activated within 10 days from the Equipment delivery date, your Service will be automatically activated by EarthLink and the primary method of payment on file will be charged for the first month’s invoice, plus applicable taxes and fees. Upon activation, Subscribers will have access to the internet. If EarthLink is unable to activate Wireless Home Internet Services, EarthLink reserves the right to cancel such Services.
            </li>
          </ul>
        }
      </div>
    </Modal>
  )
}

WhiModal.propTypes = {
  showWhiAddedModal: PropTypes.bool.isRequired,
  setShowWhiAddedModal : PropTypes.func.isRequired,
  selectedProduct: PropTypes.shape({
    shortName: PropTypes.string.isRequired,
  }),
  handleConfirmOrder :  PropTypes.func.isRequired,
  handleAddIntToCart :  PropTypes.func.isRequired
};

export default WhiModal
