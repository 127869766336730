/* eslint-disable */
import React, { useEffect, useState, useMemo } from 'react';
import { useBuyFlow, useCallCenterFlag, useServiceablitityData, useSessionData, useCartData } from '../../../hooks/order';
import { Row, Col } from 'antd';
import InternetPlans from '../Products/InternetPlans';
import MobilePhonePlans from '../Products/MobilePhonePlans';
import { getAggregatedAddress, getHomeUrl, getNbc, vendorInformation, getCallLogId } from '../../../common/common';
import { useDispatch } from 'react-redux';
import { saveFirstStep, saveInternetPlan, saveVasPlanInfo, saveWirelessPlanInfo, setTFN, getBundleDetails, CheckScheduleAppt, setActiveProductTab, setSelectedBundle } from '../../../store/action/OrderAction';
import { useNavigate } from 'react-router-dom';
import { MOBILE_SALES_TFN, routesConfig, WHI_TFN } from '../../../util/util';
import { EECproductDetailView } from '../../../common/GTMScripts';
import useWindowDimensions from '../../common/useWindowDimensions';
import CartInformation from '../../common/CartInformation';
import Bundles from './Bundles'


const Product = () => {
  const { isMobile } = useWindowDimensions();
  const { showCallNow, showPromoStickers } = useCallCenterFlag();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { buyflow } = useBuyFlow();
  const callLogId = getCallLogId();
  const { serviceabilityCheck, normalizedAddress, } = useServiceablitityData();
  const { isInternetAdded, isVasAdded, isMobileAdded, isBundleAdded, internetPlan, vasPlanInfo, wirelessCartInfo, updateSelectedBundle, isBundleVisible, servRef, isWhiAdded } = useCartData();
  const { plans = [], mobileProducts = [], fixedWirelessProducts = [], bundles = [], bundlesDetails = [] } = serviceabilityCheck;
  const { getMobilePrice } = useCartData();
  const [scroll, setScroll] = useState(false);
  const showInternet = buyflow && (buyflow === 'all' || buyflow === 'internet') && (plans.length > 0 || fixedWirelessProducts.length > 0);
  const showMobile = buyflow && (buyflow === 'all' || buyflow === 'wireless') && mobileProducts.length > 0;
  const showBundle = buyflow && (buyflow === 'all' || buyflow === 'bundle') && bundles.length > 0;
  const showTab = buyflow === 'all' && showInternet && (showMobile || showBundle);
  const { sessionResponse, extendedInfo, customTFN, setActiveProductTab: setActiveProductTabValue } = useSessionData();
  const activeTab = useMemo(() => {
    if (setActiveProductTabValue) {
      return setActiveProductTabValue;
    } else if (showInternet) {
      return 'internet';
    } else {
      return 'all';
    }
  }, [setActiveProductTabValue, showInternet]);
  const [tab, setActiveTab] = useState(activeTab);
  const { agentId } = sessionResponse;
  const [check300, set300prod] = useState(false);
  const [minPrices, setMinPrices] = useState({ mobileProducts: '', internetProducts: '', overall: '', bundles: '' });
  const [bundlePrices, setBundlePrices] = useState([]);
  const allProducts = [...plans, ...mobileProducts, ...fixedWirelessProducts];

  useEffect(() => {
    if (isBundleVisible) {
      dispatch(getBundleDetails((bundles.map(el => el.servRef).join(','))));
    }
  }, [bundles, isBundleVisible])
  const getBundlesMinPrice = useMemo(() => {
    const bundleMinPrices = bundlesDetails?.map((bundle) => {
      const productPrice = [1, 2, 3, 4].reduce((price, key) => {
        if (!bundle[`servCode${key}`] || !bundle[`servLevel${key}`]) {
          return price;
        }
        if (bundle[`servLevel${key}`] === '*') {
          return price + allProducts.reduce((minPrice, prod) => {
            if (prod.servCode?.toLowerCase() === bundle[`servCode${key}`].toLowerCase() && prod.servLevel) {
              const totalPrice = Number(prod.price) + (Number(prod.equipmentCost) || 0);
              return (!minPrice || totalPrice < minPrice) ? totalPrice : minPrice;
            }
            return minPrice;
          }, 0);
        }
        const currentProduct = allProducts.find((product) => product.servLevel?.toLowerCase() === bundle[`servLevel${key}`]?.toLowerCase());
        return currentProduct ? price + (Number(currentProduct.price) || 0) + (Number(currentProduct.equipmentCost) || 0) : price;
      }, 0);
      return (Number(productPrice) + Number(bundle.creditAmount)).toFixed(2);
    }) || [];
    return bundleMinPrices.length ? Math.min(...bundleMinPrices) : 0;
  }, [allProducts.length, bundlesDetails.length])
  useEffect(() => {
    // tfn fliping
    if ((showCallNow || showPromoStickers) && window.eInitTFN && agentId === 'lp_elnkwww_giftcard') {
      window.eInitTFN({
        rst: 'b43a3b6489e18393950347784b6ce95',
        tfns: {
          format: 'xxx-xxx-xxxx',
          dtfn: '844-660-1462',
          mtfn: '833-601-5645',
        },

      });
    }

  }, [window.eInitTFN, showCallNow]);


  const checkProductSpd = () => {
    if (plans?.length > 0) {
      plans.map(product => {
        const { downstreamSpd = 0 } = product;
        const productSpd = (downstreamSpd / 1000 === 300)
        const vendor = vendorInformation(product, extendedInfo)
        if (vendor.toLowerCase() === 'at&t' && productSpd) {
          return set300prod(true)
        }
        return false
      })
    }
  }
  useEffect(() => {
    if (!plans.length && !mobileProducts.length && !fixedWirelessProducts.length) {
      window.location.href = getHomeUrl();
    }
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 160);
    });
    dispatch(saveFirstStep(false))
    dispatch(saveVasPlanInfo({}));
    EECproductDetailView([...plans, ...mobileProducts])
    // IN CASE ONLY WHI RETURNED, CHANGE HEADER TFN
    if (!plans.length && !mobileProducts.length && fixedWirelessProducts.length) {
      dispatch(setTFN(WHI_TFN));
    } else if ((!plans.length && mobileProducts.length && !fixedWirelessProducts.length) || buyflow === 'wireless') {
      dispatch(setTFN(MOBILE_SALES_TFN));
    } else {
      const nbcScore = getNbc();
      dispatch(setTFN(nbcScore));

    }

    checkProductSpd();

  }, []);

  useEffect(() => {
    let minPricesObject = minPrices;

    if (!minPrices.mobileProducts) {
      const prices = mobileProducts.map((product) => getMobilePrice(product, 'product'));
      const minPrice = Math.min(...prices);

      minPricesObject.mobileProducts = minPrice || '0.00';
    }
    if (!minPrices.internetProducts) {
      const prices = [...plans, ...fixedWirelessProducts].map((product) => product.price);
      const minPrice = Math.min(...prices);

      minPricesObject.internetProducts = minPrice || '0.00';
    }
    if (!minPrices.bundles && isBundleVisible && getBundlesMinPrice) {
      minPricesObject.bundles = getBundlesMinPrice || '0.00';
    }

    setMinPrices(minPricesObject);
  }, [mobileProducts, plans, fixedWirelessProducts, isBundleVisible, bundlesDetails?.length, getBundlesMinPrice]);

  useEffect(() => {
    if (minPrices.internetProducts && minPrices.mobileProducts) {
      let overallMinPrice = 0;
      if (minPrices?.bundles !== '') {
        overallMinPrice = Math.min(minPrices.internetProducts, minPrices.mobileProducts, minPrices?.bundles);
      } else {
        overallMinPrice = Math.min(minPrices.internetProducts, minPrices.mobileProducts);
      }
      setMinPrices({ ...minPrices, overall: overallMinPrice || '0.00' });
    }
  }, [minPrices.internetProducts, minPrices.mobileProducts, minPrices.bundles]);

  useEffect(() => {
    if (isBundleVisible) {
      dispatch(getBundleDetails((bundles.map(el => el.servRef).join(','))));
    }
  }, [bundles, isBundleVisible])

  useEffect(() => {
    updateSelectedBundle();
  }, [
    isInternetAdded,
    isMobileAdded,
    isVasAdded,
    isBundleAdded,
    bundlesDetails,
    dispatch,
    internetPlan,
    wirelessCartInfo,
    vasPlanInfo,
    servRef,
    isWhiAdded
  ]);
  const labelWithCharS = (val) => val.length > 1 ? 's' : '';

  const planLabel = (val) => {
    if (!isMobile) {
      return 'plan' + labelWithCharS(val);
    }
    return '';
  }
  const getBundleLength = () => {
    if (isBundleVisible) {
      return bundlesDetails?.length;
    }
    return 0;
  }

  const handleRestAddress = () => {
    navigate(routesConfig.home)
    dispatch(setSelectedBundle(null));
    dispatch(saveInternetPlan({}))
    dispatch(saveWirelessPlanInfo({
      wirelessPlanInfo: [],
      wirelesServeRef: ''
    }));
    dispatch(setActiveProductTab(''))
  }

  const showCallNowFunc = () => {
    if (showCallNow) {
      return 'agent-full-width';
    }
    return '';
  }
  return (
    <>
      <div className={`elink-container p-top-5 height-big-screen ${(showCallNow || showPromoStickers) ? 'call_agent_online' : ''}`}>
        <div className="result-heading" >
          <h1 style={{ marginBottom: '0' }}>The results are in!</h1>

          <p>Let&apos;s get you connected at <b>{getAggregatedAddress(normalizedAddress)}</b> </p>
          <button onClick={() => handleRestAddress()} style={{ marginTop: '4px' }}>Change Address</button>
        </div>

        {showTab && <div className={`filters-top ${scroll ? 'tabfixed' : ''}`}>
          <div className="elink-container">
            <Row className={`top-filter ${!isBundleVisible ? 'without-bundle' : ''}`} >
              <Col span={6} onClick={() => setActiveTab('all')}>
                <div className={`single-filter text-center ${tab === 'all' && 'active-filter'}`}>

                  <h4>Show All {isMobile ? '' : 'plans'}  <span>({plans?.length + mobileProducts?.length + fixedWirelessProducts?.length + getBundleLength()})</span></h4>
                  <p>${minPrices.overall}  {isMobile ? '+' : 'and up'} </p>
                </div>


              </Col>
              <Col span={6} onClick={() => setActiveTab('internet')}>
                <div className={`single-filter text-center ${tab === 'internet' && 'active-filter'}`}>
                  <h4>Internet  {planLabel([...plans, ...fixedWirelessProducts])} <span>({plans.length + fixedWirelessProducts.length})</span></h4>
                  <p>${minPrices.internetProducts} {isMobile ? '+' : 'and up'} </p>
                </div>
              </Col>
              {
                mobileProducts?.length > 0 && <Col span={6} onClick={() => setActiveTab('mobile')}>
                  <div className={`single-filter text-center ${tab === 'mobile' && 'active-filter'}`}>
                    <h4>Mobile  {planLabel(mobileProducts)} <span>({mobileProducts.length})</span></h4>
                    <p>${minPrices.mobileProducts} {isMobile ? '+' : 'and up'} </p>
                  </div>
                </Col>
              }

              {isBundleVisible &&
                <Col span={6} onClick={() => setActiveTab('bundle')}>
                  <div className={`single-filter text-center ${tab === 'bundle' && 'active-filter'}`}>
                    <h4>Bundled  {planLabel(bundles)} <span>({bundlesDetails?.length})</span></h4>
                    <p>${minPrices.bundles && Number(minPrices.bundles)?.toFixed(2)} {isMobile ? '+' : 'and up'}</p>
                  </div>
                </Col>
              }
            </Row>
          </div>
        </div>}
        {(buyflow === 'otherMobile' || buyflow === 'otherInt') &&
          <div className="info-tag">
            <i className="fa fa-info-circle" aria-hidden="true"></i> {buyflow === 'otherMobile' ? 'Internet' : 'Mobile'} plans are not available, check other plans available for this address.
          </div>}
        <div className={`plans-row ${!showTab ? 'no-tabs' : ''}`}>

          <Row>
            <Col span={16} className={(plans.length === 0 && fixedWirelessProducts.length === 0) && tab !== 'internet' ? 'mobileTop' : `intTop responsive-width-set ${showCallNowFunc()}`}>
              {(tab === 'all' || tab === 'internet') && (showInternet || buyflow === 'otherInt') && <div className="internet-outer"><InternetPlans serviceabilityCheck={serviceabilityCheck} productSpd300={check300} customTFN={customTFN} /></div>}
              {(tab === 'all' || tab === 'mobile' || buyflow === 'wireless') && (showMobile || buyflow === 'otherMobile') && <div className="mobilephone-outer"><MobilePhonePlans serviceabilityCheck={serviceabilityCheck} customTFN={customTFN} /></div>}
              {isBundleVisible &&
                <>
                  {(tab === 'all' || tab === 'bundle') && (showBundle || buyflow === 'bundle') &&
                    <div className="bundle-outer">
                      <Bundles serviceabilityCheck={serviceabilityCheck} bundlePrices={bundlePrices} setBundlePrices={setBundlePrices} />
                    </div>}
                </>
              }
            </Col>
            <Col span={8} className={`p-left-1 ${(showCallNow) ? 'agent-hidden' : ''}`}>
              <CartInformation />
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}


export default Product;
