/* eslint-disable no-console */
import { sessionKeys } from '../helpers/sessionKeysEnum';
import { getConfigs } from '../config';
import { checkUrlRegex } from '../util/regex';
import { parse, parseISO, format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

const config = getConfigs();

export const getAccessToken = () => {
  return sessionStorage.getItem(sessionKeys.accessToken);
};
export const setAccessToken = (value) => {
  sessionStorage.setItem(sessionKeys.accessToken, value);
};

export const getCjevent = () => {
  return sessionStorage.getItem(sessionKeys.cjevent);
};
export const setCjevent = (value) => {
  sessionStorage.setItem(sessionKeys.cjevent, value);
};

export const getTFN = () => {
  return sessionStorage.getItem(sessionKeys.tfn);
};
export const setTFN = (value) => {
  sessionStorage.setItem(sessionKeys.tfn, value);
};

export const getHomeUrl = () => {
  return sessionStorage.getItem(sessionKeys.HomeUrl);
};
export const setHomeUrl = (value) => {
  sessionStorage.setItem(sessionKeys.HomeUrl, value);
};

export const getRefreshToken = () => {
  return sessionStorage.getItem(sessionKeys.refreshToken);
};
export const setRefreshToken = (value) => {
  sessionStorage.setItem(sessionKeys.refreshToken, value);
};

export const getNbc = () => {
  return sessionStorage.getItem(sessionKeys.nbc) || false;
};

export const setNbc = (value) => {
  sessionStorage.setItem(sessionKeys.nbc, value);
};

export const getAgentId = () => {
  return sessionStorage.getItem(sessionKeys.agentId);
};
export const setAgentId = (value) => {
  sessionStorage.setItem(sessionKeys.agentId, value);
};

export const getSessionOrderId = () => {
  return sessionStorage.getItem(sessionKeys.sessionOrderId);
};
export const setSessionOrderId = (value) => {
  sessionStorage.setItem(sessionKeys.sessionOrderId, value);
};
export const clearSessionOrderId = () => {
  sessionStorage.removeItem(sessionKeys.sessionOrderId);
};

export const getPromoCode = () => {
  return sessionStorage.getItem(sessionKeys.promoCode);
};
export const setPromoCode = (value) => {
  sessionStorage.setItem(sessionKeys.promoCode, value);
};

export const getBusinessPromoCode = () => {
  return sessionStorage.getItem(sessionKeys.businessPromoCode);
};
export const setBusinessPromoCode = (value) => {
  sessionStorage.setItem(sessionKeys.businessPromoCode, value);
};

export const setCustId = (value) => {
  sessionStorage.setItem(sessionKeys.custId, value);
};
export const getCustId = () => {
  return sessionStorage.getItem(sessionKeys.custId);
};
export const setCallLogId = (value) => {
  sessionStorage.setItem(sessionKeys.callLogId, value);
};
export const getCallLogId = () => {
  return sessionStorage.getItem(sessionKeys.callLogId);
};

//  TO SAVE FIRST SET OF SECURITY QUESTION TO SESSION STORAGE
export function setSecurityQuestion(question, answer) {
  sessionStorage.setItem(sessionKeys.securityQue, question);
  sessionStorage.setItem(sessionKeys.securityAns, answer);
}
//  TO GET FIRST SET OF SECURITY QUESTION TO SESSION STORAGE
export function getSecurityQuestion(question, answer) {
  let securityQuestionModel = {};
  securityQuestionModel.question = sessionStorage.getItem(
    sessionKeys.securityQue,
    question
  );
  securityQuestionModel.answer = sessionStorage.getItem(
    sessionKeys.securityAns,
    answer
  );
  return securityQuestionModel;
}

export const getValid$Cost = (cost) => {
  if (!isUndefinedNullOrEmpty(cost) && cost.toString().indexOf('$') < 0) {
    return '$' + cost;
  }
  return cost;
};
export const getValidIntCost = (cost) => {
  if (!isUndefinedNullOrEmpty(cost) && cost.toString().indexOf('$') < 0) {
    return Number(cost);
  } else {
    while (!isUndefinedNullOrEmpty(cost) && cost.toString().indexOf('$') >= 0) {
      cost = cost.substring(1);
    }
    return Number(cost);
  }
};

const getFixedCost = (charges) => {
  if (!isNaN(charges) && !isUndefinedNullOrEmpty(charges)) {
    return charges.toFixed(2);
  }
  return '';
};

export const getFixedCostWith$ = (charges) => {
  if (getFixedCost(charges)) {
    return '$' + getFixedCost(charges);
  }
  return '';
};

// CHECK FOR VALUE TO BE EMPTY, NULL OR UNDEFINED
export function isUndefinedNullOrEmpty(value) {
  if (value === undefined || value === '' || value === null) {
    return true;
  }
  return false;
}

export function isEmpty(value) {
  if (value === '') {
    return true;
  }
  return false;
}

export function getSegregatedAddress(address) {
  let segregatedAddress = {};
  //  Extract address fields by splitting
  const addressFields = address.split(', ');
  let i = 1;
  segregatedAddress.address1 = isUndefinedNullOrEmpty(addressFields[0])
    ? ''
    : addressFields[0].trim();
  // if addressFields length < 5 then we assume address2 is not there
  if (addressFields.length === 5) {
    segregatedAddress.address2 = isUndefinedNullOrEmpty(addressFields[1])
      ? ''
      : addressFields[1].trim();
    i = 2;
  } else {
    segregatedAddress.address2 = '';
  }
  segregatedAddress.city = isUndefinedNullOrEmpty(addressFields[i])
    ? ''
    : addressFields[i].trim();
  segregatedAddress.state = isUndefinedNullOrEmpty(addressFields[i + 1])
    ? ''
    : addressFields[i + 1].trim();
  segregatedAddress.zip = isUndefinedNullOrEmpty(addressFields[i + 2])
    ? ''
    : addressFields[i + 2].trim();
  return segregatedAddress;
}
// input arg must be object of length 5 having addr1, addr2, city, state, zip in this order only.
export function getAggregatedAddress(addrObj, returnObj = false) {
  let aggregatedAddress = '';
  const len = !isUndefinedNullOrEmpty(addrObj)
    ? Object.keys(addrObj).length
    : 0;
  if (len > 0) {
    aggregatedAddress = `${
      isUndefinedNullOrEmpty(addrObj.address1) ? '' : addrObj.address1 + ', '
    }${
      isUndefinedNullOrEmpty(addrObj.address2) ? '' : addrObj.address2 + ', '
    }${isUndefinedNullOrEmpty(addrObj.city) ? '' : addrObj.city + ', '}${
      isUndefinedNullOrEmpty(addrObj.state) ? '' : addrObj.state
    }${
      isUndefinedNullOrEmpty(addrObj.zipCode)
        ? ''
        : ', ' + addrObj.zipCode.substring(0, 5)
    }`;
    return returnObj
      ? {
        address: aggregatedAddress,
        hasMore: false,
      }
      : aggregatedAddress;
  } else {
    return null;
  }
}

export function aggregateAddressAndSec(addrObj) {
  let aggregatedAddress = '';
  const len = !isUndefinedNullOrEmpty(addrObj)
    ? Object.keys(addrObj).length
    : 0;
  if (len > 0) {
    aggregatedAddress = `${
      isUndefinedNullOrEmpty(addrObj.address1) ? '' : addrObj.address1 + ', '
    }${
      isUndefinedNullOrEmpty(addrObj.address2) ? '' : addrObj.address2 + ', '
    }${addrObj.entries <= 1 ? '' : '(' + addrObj.entries + ' more entries) '}${
      isUndefinedNullOrEmpty(addrObj.city) ? '' : addrObj.city + ', '
    }${isUndefinedNullOrEmpty(addrObj.state) ? '' : addrObj.state}${
      isUndefinedNullOrEmpty(addrObj.zipCode) ? '' : ', ' + addrObj.zipCode
    }`;
    return {
      address: aggregatedAddress,
      hasMore: addrObj.entries <= 1 ? false : true,
    };
  } else {
    return null;
  }
}

/**
 * Build a url string from an array of segments.
 *
 * @param {array} segments - The url segments to join and form url string.
 * @param {object} options
 *       {boolean} options.trailingSlash - If true, keep/insert trailing slash.
 *                 If false, strip trailing slash. Default is false.
 * @returns {string} The url.
 */
export const buildURL = (segments, options = {}) => {
  if (!Array.isArray(segments)) {
    throw new Error('Segments expected to be array');
  }

  const { trailingSlash = false } = options;
  const url = segments
    .filter((segment) => typeof segment === 'string')
    .join('/')
    .replace(/\/+/g, '/')
    .replace(/^(.*)\/+$/g, '$1');

  return trailingSlash ? `${url}/` : url;
};

export const getQueryStringValue = (key) => {
  const qs = window.location.href.split('?');
  const searchParams = new URLSearchParams(qs);
  const value = searchParams.get(key) || '';
  return value;
};

export const getValidAcctEmail = (email, isElnkMailboxReq) => {
  let validId = isUndefinedNullOrEmpty(email) ? '' : email;
  if (!isUndefinedNullOrEmpty(email) && isElnkMailboxReq) {
    validId = email.split('@')[0].replace(/[^a-zA-Z0-9-_]/g, '');
    while (
      (!isNaN(Number(validId.charAt(0))) ||
        ['-', '_'].includes(validId.charAt(0))) &&
      validId !== ''
    ) {
      validId = validId.substr(1, validId.length - 1);
    }
    return validId.substring(0, 16);
  }
  return validId;
};

export const formatTFN = (TFN) => {
  if (TFN && TFN.length === 10) {
    return TFN.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  }
  return TFN;
};

export const checkTollFreeNumber = (number) => {
  if (!isUndefinedNullOrEmpty(number)) {
    if (
      number.startsWith('+1800') ||
      number.startsWith('+1888') ||
      number.startsWith('+1877') ||
      number.startsWith('+1866') ||
      number.startsWith('+1855') ||
      number.startsWith('+1833') ||
      number.startsWith('+1844')
    ) {
      return true;
    }
    return false;
  }
  return false;
};

export const SECURITY_TOOLS_URL = config.SECURITY_TOOLS_URL;

// export const MOBILE_URL = getBaseUrl() + 'mobile/'

// Redirect use to earthlink.net security and tools page.
// export const redirectToSecurityTools = () => {
//   window.location.href = getBaseUrl() + SECURITY_TOOLS_URL;
// }

// // Redirect use to earthlink.net easy tech page.
// export const redirectToEasytech = () => {
//   window.location.href = getBaseUrl() + EASY_TECH_URL;
// }

export const vendorInformation = (planInfo, extendedInfo) => {
  if (extendedInfo) {
    const result = extendedInfo.find(
      ({ productId }) => productId === planInfo.servRef
    );
    if (result) {
      return result.vendor;
    } else {
      return 'local telephone company';
    }
  }
};

export const vendorInstallationDay = (planInfo, extendedInfo) => {
  if (extendedInfo) {
    const result = extendedInfo.find(
      ({ productId, vendor }) =>
        productId === planInfo.servRef &&
        (vendor === 'Frontier' ||
          vendor === 'Lumen' ||
          vendor === 'CenturyLink IMA' ||
          vendor === 'CenturyLink')
    );
    if (result) {
      return '5-7 days';
    }
    return 'about a week';
  }
};
// convert Date time  seprated using 'to' (date to startTime to endTime) to UTC date time
// 2021-12-06 to 2:00 pm to 4:00 pm ==> 2021-12-06T14:00:00.000Z|2021-12-06T16:00:00.000Z

export const convertDateTimeToUTC = (dateTime) => {
  if (!dateTime) {
    return '';
  }

  const segregatedDate = dateTime.split('to');
  if (segregatedDate.length === 3) {
    // Extract the date, start time, and end time
    const date = segregatedDate[0].trim();
    const startTime = `${date} ${segregatedDate[1].trim()}`;
    const endTime = `${date} ${segregatedDate[2].trim()}`;

    try {
      // Parse and convert start time to UTC
      const parsedStartTime = parse(startTime, 'yyyy-MM-dd h:mm a', new Date());
      const utcStartTime = format(
        parsedStartTime,
        'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\''
      );
        // Parse and convert end time to UTC
      const parsedEndTime = parse(endTime, 'yyyy-MM-dd h:mm a', new Date());
      const utcEndTime = format(parsedEndTime, 'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\'');

      return `${utcStartTime}|${utcEndTime}`;
    } catch (error) {
      console.error('Error parsing date/time:', error);
      return '';
    }
  }

  return '';
};
// convert Date time  seprated using 'to' (date to startTime to endTime) to UTC date time
// 2021-12-06T14:00:00.000Z|2021-12-06T16:00:00.000Z ==> 2021-12-06 between 2:00 pm and 4:00 pm
export const convertInstallWindowToReadableString = (window) => {
  if (!window) {
    return '';
  }
  const getScheduleData = window.split('|');
  const getDateTime = getScheduleData.filter((splitTime) => splitTime !== '0');
  if (getDateTime.length < 2) {
    return '';
  }
  const date = format(parseISO(getDateTime[0]), 'MMMM dd, yyyy');
  const startTime = formatInTimeZone(parseISO(getDateTime[0]), 'UTC', 'h:mm a');
  const endTime = formatInTimeZone(parseISO(getDateTime[1]), 'UTC', 'h:mm a');

  return `${date} between ${startTime} and ${endTime}`;
};

export const replaceServiceName = (serviceName) => {
  let serviceTitle = serviceName
    ?.toLowerCase()
    ?.replace('earthlink', '')
    .split('x')[0];
  let serviceSplit = serviceTitle && serviceTitle.split(' ');
  const lastElement = serviceSplit?.pop();
  if (/\d/.test(lastElement)) {
    serviceSplit.push(lastElement.toUpperCase());
    return serviceSplit.join(' ');
  }
  return serviceTitle;
};

// calculate mobile plans servrefs
export const getWirelessServRef = (wirelessPlanInfo) => {
  const checkQty = (plan) => {
    let servRef = '';
    if (plan.quantity > 1) {
      servRef = plan.servRef + `,${plan.servRef}`.repeat(plan.quantity - 1);
      return servRef;
    }
    return plan.servRef;
  };

  let servRef = '';
  wirelessPlanInfo.forEach((item, i) => {
    servRef = i === 0 ? checkQty(item) : servRef + `,${checkQty(item)}`;
  });
  return servRef;
};

/*
  Firefox allows non-numeric characters in numeric input,
  so this helper is used to prevent inputting alpha characters
*/
export const handleNumericKeyPress = (e) => {
  const charCode = e.charCode !== null ? e.charCode : e.keyCode;
  const charString = String.fromCharCode(charCode);

  if (!charString.match(/^[0-9]+$/)) {
    e.preventDefault();
  }
};

export const handleAlphaKeyPress = (e) => {
  const charCode = e.charCode !== null ? e.charCode : e.keyCode;
  const charString = String.fromCharCode(charCode);

  if (!charString.match(/^[A-Za-z\s]+$/)) {
    e.preventDefault();
  }
};

export const getUpdatedServRef = (plan, servRef) => {
  const sevRefArr = servRef.split(',');
  const planQty = plan.quantity ? plan.quantity : 1;
  for (let i = 0; i < planQty; i++) {
    const ind = sevRefArr.indexOf(plan.servRef);
    sevRefArr.splice(ind, 1);
  }
  return sevRefArr.toString();
};

export const replaceURLWithHTMLLinks = (text) => {
  // Check string for any url patterns and wrap them in anchor tags
  return text?.replace(checkUrlRegex, function (url) {
    let href = url;
    if (!/^https?:\/\//i.test(url)) {
      href = 'https://' + url;
    }
    return `<a href="${href
      .trim()
      ?.toLowerCase()}" target="_blank">${url.trim()}</a> `;
  });
};

export const getProductPrice = (productDetails, serviceTerm) => {
  const { price, servRef } = productDetails;
  let getServX4YPromo = {};
  if (serviceTerm?.length > 0) {
    getServX4YPromo = serviceTerm
      .filter((service) => service.servServRef === servRef)[0]
      ?.servX4Y.find((item) => item.servType.toLowerCase() === 'service');
    return getServX4YPromo?.servX4YAmount
      ? getValid$Cost(getServX4YPromo?.servX4YAmount)
      : getValid$Cost(price);
  } else {
    return getValid$Cost(price);
  }
};
export const getBundleProductPrice = (productDetails, serviceTerm) => {
  const { price, servRef } = productDetails;
  let getServX4YService;
  if (serviceTerm?.length > 0) {
    getServX4YService =
      serviceTerm
        ?.filter((service) => service.servServRef === servRef)[0]
        ?.servX4Y.find((item) => item.servType?.toLowerCase() === 'service') ||
      {};
    const { servX4YAmount } = getServX4YService;
    return servX4YAmount ? getValid$Cost(servX4YAmount) : getValid$Cost(price);
  } else {
    return getValid$Cost(price);
  }
};
// get promo price
export const getPromoPrice = (data, serviceTerm) => {
  let getServX4YPromo = {};
  if (serviceTerm && serviceTerm?.length > 0) {
    getServX4YPromo = serviceTerm
      .filter((service) => service.servServRef === data.servRef)[0]
      ?.servX4Y.find((item) => item.servType === 'Promo');
    return getValid$Cost(getServX4YPromo?.servX4YAmount);
  }
  return '';
};
