import React from 'react';
import { convertInstallWindowToReadableString } from '../../common/common';
import { useCartData, useScheduleDate, useVendor } from '../../hooks/order';
import { VENDORS } from '../../util/util';

const InstalltionInfo = (props) => {
  const { scheduleDateTime } = useScheduleDate();
  const { isWhiAdded, getMrcPosAmount, chargeMrcPosIpbb, installCost } = useCartData();
  const dateTime = convertInstallWindowToReadableString(scheduleDateTime);
  const { oneTimeCharge, internetPlan } = props;
  const [reqInstallDate = '', reqInstallTime = ''] = dateTime ? dateTime.split('between') : [];
  const { vendor } = useVendor();

  const isVendorVerizon = vendor?.split(' ')[0]?.toLowerCase() === 'verizon';

  const installationInfoCardLabel = () => {
    if (isVendorVerizon) {
      return <span>with <b>${installCost}</b> charged today to install the service.</span>;
    } else if (getMrcPosAmount > 0 && chargeMrcPosIpbb && internetPlan?.servCode === 'ipbb') {
      return <></>
    }
    return <span><b>with {oneTimeCharge}</b> charged today.</span>;
  }

  return (
    <>
      <div className="cart-info-panel common-pannel result-fixed-m">
        <h3>{isWhiAdded ? 'Activation Information' : 'Installation Information'}</h3>
        <ul>
          {isWhiAdded ?
            <>
              <li>
                Processing & Handling Fee, <b>{oneTimeCharge}</b>, collected today. WHI router ships same day and arrives within 2 to 3 business days (M-F). Orders may deliver on Saturdays.
              </li>
              <li>
                If your Service has not been activated within 10 days from the Equipment delivery date, your Service will be automatically activated by EarthLink and the primary method of payment on file will be charged for the first month’s invoice, plus applicable taxes and fees. Upon activation, Subscribers will have access to the internet. If EarthLink is unable to activate Wireless Home Internet Services, EarthLink reserves the right to cancel such Services.
              </li>
            </>
            :
            <>
              <li><b>Professional installation</b> provided by {vendor ? vendor + '.' : 'local telephone company'} {installationInfoCardLabel()}</li>
              {
                scheduleDateTime ?
                  <li>
                    <div className="list-ct">
                      Your Installation date will be&nbsp;
                      <b>{reqInstallDate}</b> between <b>{reqInstallTime}</b>.
                    </div>
                  </li>
                  :
                  (!isVendorVerizon && <>
                    <li>
                      <div className="list-ct">Look for a text from us <span className="bolder">confirming your installation </span> appointment details <span className="bolder">within two business days.</span></div>
                    </li>

                    <li>
                      <div className="list-ct">Your service should be <span className="bolder">installed in {VENDORS.includes(vendor) ? '10-20 days' : 'about a week'}.</span></div>
                    </li>
                  </>)
              }
              {isVendorVerizon && <li>If you cancel your service, you will need to send the router back to EarthLink to avoid a non-returned Equipment fee.</li>}
              <li>
                Once installed, you will be charged for your first month&apos;s service to your designated method of payment.
              </li>
            </>
          }
        </ul>
      </div>
    </>
  )
}
export default InstalltionInfo;
